import { get, post, postToken, put, reload } from "../web.request";


export const login = async (data) => {
    return await postToken(`/userLogin`, data);
}

export const loadUser = async () => {
    return await post(`/Token`);
}

export const changePassword = async (id, data) => {
    return await put(`/superAdminPasswordChange/${id}`, data);
}
