import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string()
        .required('Password is required'),
});

export const profileValidationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    contact: Yup.string().required('Contact is required')
        .matches(
            /^[0-9]*$/,
            'Contact must be a number.'
        ).min(10, 'Contact must have 10 digits.'),
});


export const updateUserValidationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required')
        .matches(
            /^[a-zA-Z\s]*$/,
            'First Name must be a character.'
        ),

    lastName: Yup.string().required('Last Name is required')
        .matches(
            /^[a-zA-Z\s]*$/,
            'Last Name must be a character.'
        ),
    contact: Yup.string().required('Contact is required')
        .matches(
            /^[0-9]*$/,
            'Contact must be a number.'
        ).min(10, 'Contact must have 10 digits.'),
});

export const addUserValidationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required')
        .matches(
            /^[a-zA-Z\s]*$/,
            'First Name must be a character.'
        ),

    lastName: Yup.string().required('Last Name is required')
        .matches(
            /^[a-zA-Z\s]*$/,
            'Last Name must be a character.'
        ),

    contact: Yup.string().required('Contact is required')
        .matches(
            /^[0-9]*$/,
            'Contact must be a number.'
        ).min(10, 'Contact must have 10 digits.'),
    email: Yup.string().trim().required("Email is required").email("Invalid email").matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email"),
});

export const changePasswordValidationSchema = Yup.object().shape({
    newPassword: Yup.string()
        .required('Password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
        ),
    confirmpass: Yup.string()
        .required('Password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
        ),
});

export const addLocationValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    address: Yup.string().required('Address is required'),
    cityId: Yup.string().required('City is required'),
    stateId: Yup.string().required('State is required'),
    countryId: Yup.string().required('Country is required'),
    zip: Yup.string().required('Zip is required')
        .matches(
            /^[0-9]{6}$/,
            'Zip must have 6 digits.'
        ),
    contact: Yup.string().required('Contact is required')
        .matches(
            /^[0-9]*$/,
            'Contact must be a number.'
        ).min(10, 'Contact must have 10 digits.'),
});

export const addProductTrackingValidationSchema = Yup.object().shape({
    product: Yup.string().required('Product is required'),
    location: Yup.string().required('Location is required'),
    move_time: Yup.string().required('Time is required'),
});

export const addProductValidationSchema = Yup.object().shape({
    productName: Yup.string().required('Name is required'),
    location: Yup.string().required('Location is required'),
    random: Yup.string().required('Random is required')
        .matches(
            /^[0-9]*$/,
            'Random must be a number.'
        ),
});

export const moveProductValidationSchema = Yup.object().shape({
    location: Yup.string().required('Location is required'),
    note: Yup.string().required('Note is required'),
});

export const addNewRequestValidationSchema = Yup.object().shape({
    productName: Yup.string().required('Product Name is required'),
    customerName: Yup.string().required('Customer Name is required'),
    poNumber: Yup.string().required('PO Number is required'),
    size: Yup.string().required('Size is required'),
    random: Yup.string().optional()
        .matches(
            /^[0-9]*$/,
            'Random must be a number.'
        ),
});

export const addToShippedValidationSchema = Yup.object().shape({
    invoiceNumber: Yup.string().required('Invoice Number is required'),
    shippingDate: Yup.string().required('Shipping Date is required'),
    containerNumber: Yup.string().required('Container Number is required'),
});

export const addMasterValidationSchema = Yup.object().shape({
    productName: Yup.string().required('Name is required'),
    random: Yup.string().required('Random is required')
        .matches(
            /^[0-9]*$/,
            'Random must be a number.'
        ),
});

export const convertToProductValidationSchema = Yup.object().shape({
    location: Yup.string().required('Location is required'),
});

export const addStockValidationSchema = Yup.object().shape({
    productId: Yup.string().required('Product is required'),
    quantity: Yup.string().required('Quantity is required')
        .matches(
            /^[0-9]*$/,
            'Quantity must be a number.'
        ),
});

export const changePasswordValidationSchemas = Yup.object().shape({
    newPassword: Yup.string()
        .required('New Password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
            'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
        ),
    confirmpass: Yup.string()
        .required('Confirm Password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
            'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
        ),
    currentPassword: Yup.string().required('Password is required.'),
});