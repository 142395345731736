import React, { useEffect, useState } from 'react'
import { useStyles } from '../../helper/Theme';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import PageHeading from '../../components/common/PageHeading';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { getMasterDetails } from '../../Service/master.service';
import { useLocation } from 'react-router-dom'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import CommonButton from '../../components/common/Button';

const NewMasterDetails = () => {
  const classes = useStyles();
  const [masterProductData, setMasterProductData] = useState([])
  const { state } = useLocation()
  const Navigate = useNavigate()

  const getSampleProductData = async (id) => {
    const res = await getMasterDetails(id);
    if (res.status == true) {
      setMasterProductData(res.data)
    }
  }

  useEffect(() => {
    if (state && state.id) {
      getSampleProductData(state?.id)
    }
  }, [state])


  return (
    <>
      <PageHeading heading='Master Details' />
      <CommonButton onClick={() => Navigate(-1)} variant="contained" color="primary" buttonName="Back" />
      <div style={{
        marginTop: '20px',
      }}> </div >
      <Box className={classes.cardContainer} sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
        <Stack flexDirection={'row'} gap={3} alignItems={'center'}>
          <Stack gap={2}>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} >Product Name :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'800'}>{masterProductData && masterProductData?.name == null ? '-' : masterProductData?.name}</Typography>
            </Stack>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px', whiteSpace: 'nowrap' }} >Description :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{masterProductData && masterProductData?.description == null ? '-' : masterProductData?.description}</Typography>
            </Stack>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} >Random :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{masterProductData && masterProductData?.random == null ? '-' : masterProductData?.random}</Typography>
            </Stack>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} >Size :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{masterProductData && masterProductData?.size == null ? '-' : masterProductData?.size}</Typography>
            </Stack>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px' }}>Finish :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{masterProductData && masterProductData?.finish == null ? '-' : masterProductData?.finish}</Typography>
            </Stack>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px' }}>Created At :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{masterProductData && masterProductData?.createdAt == null ? '-' : moment(masterProductData?.createdAt).format("LLL")}</Typography>
            </Stack>

          </Stack>
        </Stack>

      </Box >
    </>
  )
}

export default NewMasterDetails