import React, { useState, useEffect } from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Stack, debounce, } from '@mui/material'
import { CloseIcon } from '../../../components/Constants';
import CommonButton from '../../../components/common/Button';
import CommonAutocomplete from '../../../components/common/Autocomplete';
import CommonInput from '../../../components/common/Input';
import { useFormik } from 'formik';
import { addStockInitialValues } from '../../../helper/initialValues';
import { addStockValidationSchema } from '../../../helper/validation';
import { stockAdd, stockDetails } from '../../../Service/stock.service';
import Notification from '../../../components/common/Notification/Notification';
import { productDropDown, stockproductDropdown } from '../../../Service/product.service';
import { locationDropDown } from '../../../Service/location.service';
import CommonAutocomplete1 from '../../../components/common/Autocomplete1';

const StockAdd = ({ handleClose, modalOpen, setShow, setIsModalOpen, refresh, setRefresh, data }) => {

    const [show1, setShow1] = useState({
        show: false,
        type: '',
        message: ''
    });
    const [productlist, setProductList] = useState([]);
    const [locationData, setLocationData] = useState([]);

    useEffect(() => {
        productDropdownData()
    }, [])

    const productDropdownData = async () => {
        const res = await productDropDown()
        if (res.status === true) {
            setProductList(res.data)
        }
    }


    useEffect(() => {
        getLocationData();
    }, [])

    const getLocationData = async () => {
        const res = await locationDropDown();
        if (res.status == true) {
            setLocationData(res.data)
        }
        else {
            setLocationData([])
        }
    }
    useEffect(() => {
        if (modalOpen && modalOpen.isEdit) {
            getStockData(modalOpen.id)
        }
    }, [modalOpen])

    const getStockData = async (id) => {
        const res = await stockDetails(id)
        if (res.status === true) {
            formik.setFieldValue('product', {
                id: res.data.productData.id,
                name: res.data.productData.name
            })
            formik.setFieldValue('productId', res.data.productId)
            formik.setFieldValue('quantity', res.data.quantity)
            formik.setFieldValue('description', res.data.description)
            formik.setFieldValue('location', {
                id: res.data.locationData.id,
                name: res.data.locationData.name
            })
            formik.setFieldValue('location_id', res.data.locationId)
            formik.setFieldValue('rank', res.data.rankNo)
        }
    }




    const formik = useFormik({
        initialValues: addStockInitialValues,
        onSubmit: async (values) => {
            try {
                const payload = {
                    "productId": values.productId,
                    "quantity": values.quantity,
                    "description": values.description,
                    "locationId": values.location_id,
                    "rankNo": values.rank || ""
                }

                let res = await stockAdd(payload);

                if (res.status == true) {
                    data([])
                    formik.resetForm();
                    setShow({
                        show: true,
                        type: 'success',
                        message: res.message || (modalOpen && modalOpen.isEdit ? "Stock Updated Successfully" : "Stock Added Successfully"),
                    });
                    handleClose()
                    setRefresh(!refresh)
                } else {
                    setShow1({
                        show: true,
                        type: 'error',
                        message: res.message || "Something went wrong!"
                    });
                }
            } catch (error) {
                setShow1({
                    show: true,
                    type: 'error',
                    message: error.message || "Something went wrong!"
                });
            }
        },
        validationSchema: addStockValidationSchema,
    });

    const debouncedOnChange = debounce((e, val) => {
        formik.setFieldValue('product', val ? val : '');
        formik.setFieldValue('productId', val && val.id ? val.id : '');
        formik.setFieldTouched('product', val && val.id ? false : true);
    }, 300);


    return (
        <div>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {modalOpen?.isEdit ? 'Edit' : 'Add'} Stock
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Box width={{ sm: '500px' }} >
                    <Stack gap={2}>
                        <Box>
                            <CommonAutocomplete1
                                data={productlist}
                                labelinput='Select Product'
                                onChange={(e, val) => {
                                    // formik.setFieldValue('product', val ? val : '')
                                    // formik.setFieldValue('productId', val && val.id ? val.id : '')
                                    // formik.setFieldTouched('product', val && val.id ? false : true);
                                    debouncedOnChange(e, val)
                                }}

                                value={formik.values.product}
                                formik={formik}
                                name='productId'
                                name1='product'
                            />
                        </Box>
                        <Box>

                            <CommonAutocomplete data={locationData} labelinput='Select Location' formik={formik} name1='location' name="location_id" value={formik.values.location}
                                onChange={(e, val) => {
                                    formik.setFieldValue('location', val)
                                    formik.setFieldValue('location_id', val && val?.id ? val?.id : '')
                                    formik.touched['location_id'] = val && val?.id ? false : true
                                }}
                            />
                        </Box>

                        <Box>
                            <CommonInput labelinput='Quantity' formik={formik} name='quantity' maxLength={5} />
                        </Box>
                        <Box>
                            <CommonInput labelinput='Rank No.' formik={formik} name='rank' maxLength={10} />
                        </Box>
                        <Box>
                            <CommonInput labelinput='Note' formik={formik} name='description' maxLength={200} />
                        </Box>

                    </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <CommonButton buttonName='cancel' size='small' color='white' onClick={handleClose} />
                <CommonButton buttonName={modalOpen?.isEdit ? 'Save' : 'Add'} size='small' type="submit" onClick={formik.handleSubmit} />
            </DialogActions>

            <Notification show={show1} setShow={setShow1} />

        </div>
    )
}

export default StockAdd