import React, { useState } from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CommonButton from '../../components/common/Button';
import CommonInput from '../../components/common/Input';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { changePassword } from '../../Service/auth.service';
import { logoutApi } from '../../state/action/authAction';
import { useNavigate } from 'react-router-dom'
import { changePasswordValidationSchemas } from '../../helper/validation';
import { changePasswordInitialValuess } from '../../helper/initialValues';
import Notification from './Notification/Notification';

const ChangePassword = ({ handleClose, show, setShow, modalOpen }) => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [show1, setShow1] = useState({
        show: false,
        type: '',
        message: ''
    });

    const formik = useFormik({
        initialValues: changePasswordInitialValuess,
        onSubmit: async (value) => {
            const payload = {
                oldPassword: value.currentPassword,
                newPassword: value.newPassword
            };
            if (value.newPassword == value.confirmpass) {
                const res = await changePassword(modalOpen.id, payload);
                if (res.status) {
                    handleClose();

                    setShow({
                        show: true,
                        type: 'success',
                        message: res.message || 'Success'
                    });
                    localStorage.removeItem('token');
                    dispatch(logoutApi());

                    Navigate('/');

                } else {

                    setShow1({
                        show: true,
                        type: 'error',
                        message: res.message || 'Error'
                    });

                }
            } else {
                setShow1({
                    show: true,
                    type: 'error',
                    message: 'Password and confirm password does not match'
                });
            }
        },
        validationSchema: changePasswordValidationSchemas,
    });
    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                Change Password
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container gap={2} maxWidth={{ sm: '400px' }} paddingY={1}>
                    <Grid item xs={12}>
                        <CommonInput
                            labelinput='Current Password'
                            passwordInput
                            formik={formik}
                            name='currentPassword'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CommonInput
                            labelinput='New Password'
                            passwordInput
                            formik={formik}
                            name='newPassword'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CommonInput
                            labelinput='Confirm Password'
                            passwordInput
                            formik={formik}
                            name='confirmpass'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CommonButton buttonName='Cancel' size='small' color='white' onClick={handleClose} />
                <CommonButton buttonName='Save' size='small' onClick={formik.handleSubmit} />
            </DialogActions>
            <Notification show={show1} setShow={setShow1} />

        </>
    )
}

export default ChangePassword