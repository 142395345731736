import { get, post, patch,deleteRequest, put,getpagination, getAllResponse } from "../web.request";

export const clientDropDown = async (data) => {
    return await get(`users/client_list_all`, data);
}

export const userList = async (query) => {
    return await get(`/userList${query}`);
}

export const deleteUser = async (id) => {
    return await deleteRequest(`/deleteUser/${id}`);
}

export const addUser = async (data) => {
    return await post(`/createUser/`, data);
}

export const statusChange = async (id) => {
    return await put(`/statusChangeUser/${id}`,);
}

export const clientDetails = async (id) => {
    return await get(`/users/client_info/${id}`);
}

export const updateUser = async (data, id) => {
    return await put(`/updateUser/${id}`, data);
}

export const changePassword = async (data, id) => {
  return await put(`changePassword/${id}`, data);
}