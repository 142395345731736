import React, { useEffect, useState } from 'react'
import { useStyles } from '../../helper/Theme';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import PageHeading from '../../components/common/PageHeading';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { sampleRequestDetail } from '../../Service/sample.service';
import { useLocation } from 'react-router-dom'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import CommonButton from '../../components/common/Button';

const SampleProductDeatils = () => {
  const classes = useStyles();
  const [sampleProductData, setSampleProductData] = useState([])
  const { state } = useLocation()
  const Navigate = useNavigate()

  const getSampleProductData = async (id) => {
    const res = await sampleRequestDetail(id);
    if (res.status == true) {
      setSampleProductData(res.data)
    }
  }

  useEffect(() => {
    if (state && state.id) {
      getSampleProductData(state?.id)
    }
  }, [state])


  return (
    <>
      <PageHeading heading='Sample Request' />
      <CommonButton onClick={() => Navigate(-1)} variant="contained" color="primary" buttonName="Back" />
      <div style={{
        marginTop: '20px',
      }}> </div>
      <Box className={classes.cardContainer} sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
        <Stack flexDirection={'row'} gap={3} alignItems={'center'}>
          <Stack gap={2}>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} >Product Name :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'800'}>{sampleProductData && sampleProductData?.productName == null ? '-' : sampleProductData?.productName}</Typography>
            </Stack>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px', whiteSpace: 'nowrap' }} >Customer Name :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{sampleProductData && sampleProductData?.customerName == null ? '-' : sampleProductData?.customerName}</Typography>
            </Stack>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} >PO Number :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{sampleProductData && sampleProductData?.poNo == null ? '-' : sampleProductData?.poNo}</Typography>
            </Stack>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} >Invoice Number :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{sampleProductData && sampleProductData?.invNo == null ? '-' : sampleProductData?.invNo}</Typography>
            </Stack>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px' }}>Container Number :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{sampleProductData && sampleProductData?.container == null ? '-' : sampleProductData?.container}</Typography>
            </Stack>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} >Size :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{sampleProductData && sampleProductData?.size == null ? '-' : sampleProductData?.size}</Typography>
            </Stack>

            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} >Finish :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{sampleProductData && sampleProductData?.finish == "" ? '-' : sampleProductData?.finish}</Typography>
            </Stack>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} >Random :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{sampleProductData && sampleProductData?.random == null ? '-' : sampleProductData?.random}</Typography>
            </Stack>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} >Date :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{sampleProductData && sampleProductData?.date == null ? '-' : moment(sampleProductData?.date).format('DD-MM-YYYY')}</Typography>
            </Stack>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} >Sample Description :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{sampleProductData && sampleProductData?.sampleDescription == "" ? '-' : sampleProductData?.sampleDescription}</Typography>
            </Stack>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} >Shipped Note :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{sampleProductData && sampleProductData?.shippedDescription == null ? '-' : sampleProductData?.shippedDescription}</Typography>
            </Stack>
          </Stack>
        </Stack>

      </Box >
    </>
  )
}

export default SampleProductDeatils