import { FormControl, Grid, Stack, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CommonButton from '../../components/common/Button'
import CommonInput from '../../components/common/Input'
import PageHeading from '../../components/common/PageHeading'
import { useFormik } from "formik";
import { profileInitialValues } from '../../helper/initialValues'
import Notification from '../../components/common/Notification/Notification'
import { profileDetails, updateProfile } from '../../Service/profile.service'
import { profileValidationSchema } from '../../helper/validation'
import { useSelector } from 'react-redux'


const inputItem = [
  {
    label: 'First Name',
    inputValue: 'Anna',
    disabled: false,
    name: 'firstName',
    maxLength: 30
  },
  {
    label: 'Last Name',
    inputValue: 'Knight',
    disabled: false,
    name: 'lastName',
    maxLength: 30
  },

  {
    label: 'Email address',
    inputValue: 'anna34@gmail.com',
    disabled: true,
    name: 'email',
    maxLength: 100
  },
  {
    label: 'mobile',
    inputValue: 'Knight',
    disabled: false,
    name: 'contact',
    maxLength: 15
  },
]

const MyProfile = () => {
  const [show, setShow] = useState({
    show: false,
    type: '',
    message: ''
  });
  const { user } = useSelector((state) => state?.root?.auth);

  const formik = useFormik({
    initialValues: profileInitialValues,
    onSubmit: async (value) => {

      const payload = {
        firstName: value.firstName,
        lastName: value.lastName,
        contact: value.contact,
      };

      const res = await updateProfile(user?.id, payload);
      if (res.status) {
        window.location.reload();
        setShow({
          show: true,
          type: 'success',
          message: res.message || 'Login Successfully'
        })
      } else {
        setShow({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong'
        })
      }
    },
    validationSchema: profileValidationSchema,
  });

  useEffect(() => {
    if (user && user?.id) {
      getProfileData(user?.id);
    }
  }, [user])

  const getProfileData = async (id) => {
    const res = await profileDetails(id);

    if (res.status) {
      formik.setFieldValue('firstName', res?.data?.firstName);
      formik.setFieldValue('lastName', res?.data?.lastName);
      formik.setFieldValue('email', res?.data?.email);
      formik.setFieldValue('contact', res?.data?.contact);
    }
  }



  return (
    <>
      <PageHeading heading='My Profile' para='Welcome to' paraspan='Asia Pacific Ceramic' />
      <Stack sx={{ padding: '0 !important', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', gap: '25px', marginTop: 2 }}>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography className='myprofile_head' fontWeight='600'>
            Personal Information
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Grid container spacing={2} sx={{ columnGap: { xs: '0', sm: '40px', md: '60px' }, rowGap: { xs: '0', sm: '10px' } }}>
            {inputItem.map((data, i) => {
              return (
                <Grid item xs={12} sm={5} md={4} key={i}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" width={250}>
                    <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                      {data.label}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography className='myprofile_input' sx={{ width: '100%' }}>
                      <CommonInput maxLength={data.maxLength} fullWidth placeholder={data.label} disabled={data.disabled} name={data.name} formik={formik} />
                    </Typography>
                  </Stack>
                </Grid>
              )
            })}
          </Grid>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="flex-start">
          <CommonButton buttonName='Save Profile Info' onClick={formik.handleSubmit} />
        </Stack>
      </Stack>
      <Notification show={show} setShow={setShow} />

    </>
  )
}

export default MyProfile