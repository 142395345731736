import React, { useEffect, useState } from 'react'
import { useStyles } from '../../helper/Theme';
import { Box, Grid, Stack, Typography } from '@mui/material';
import PageHeading from '../../components/common/PageHeading';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { stockDetails } from '../../Service/stock.service';
import { useLocation } from 'react-router-dom'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import CommonButton from '../../components/common/Button';

const StockDetails = () => {
  const classes = useStyles();
  const [stockData, setStockData] = useState([])
  const { state } = useLocation()
  const Navigate = useNavigate()

  const getStockData = async (id) => {
    const res = await stockDetails(id);
    if (res.status == true) {
      setStockData(res.data)
    }
  }

  useEffect(() => {
    if (state && state.id) {
      getStockData(state?.id)
    }
  }, [state])


  return (
    <>
      <PageHeading heading='Sample Stock Details' />
      <CommonButton onClick={() => Navigate(-1)} variant="contained" color="primary" buttonName="Back" />
      <div style={{
        marginTop: '20px',
      }}> </div>
      <Box className={classes.cardContainer} sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
        <Stack flexDirection={'row'} gap={3} alignItems={'center'}>
          <Stack gap={2}>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} >Product Name :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{stockData?.productData?.name}</Typography>
            </Stack>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px', whiteSpace: 'nowrap' }} >Quantity :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{stockData?.quantity}</Typography>
            </Stack>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px', whiteSpace: 'nowrap' }} >Location :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{stockData?.locationData?.name}</Typography>
            </Stack>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px', whiteSpace: 'nowrap' }} >Rack No :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{stockData.rankNo}</Typography>
            </Stack>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} >Description :</Typography>
              <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{stockData?.description} </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack mt={3} >
          <Typography fontSize={'20px'} fontWeight={'600'}>Master Stock Change History</Typography>
          <Timeline lineColor={'#ddd'}>
            {stockData && stockData?.stockLog?.map((data, i) => {
              var location = data?.updatedBy ? `${data?.updatedBy.firstName} ${data?.updatedBy.lastName}` : ''
              return (
                <TimelineItem
                  key={i}
                  dateText={moment(data?.createdAt).format('MM-DD-YYYY hh:mm A')}
                  style={{ color: '#e54e09' }}
                >
                  <Box sx={{ background: '#E54E0920', p: '10px 16px', maxWidth: '600px' }} className='timeline_box'>
                    <Typography fontSize={'12px'} ><span style={{ fontWeight: '600', color: '#000' }}>Last Update by :</span> {location}</Typography>
                    <Typography fontSize={'12px'} ><span style={{ fontWeight: '600', color: '#000' }}>Old Quantity :</span> {data?.lastQuantity}</Typography>
                    <Typography fontSize={'12px'} ><span style={{ fontWeight: '600', color: '#000' }}>New Quantity :</span> {data?.newQuantity}</Typography>

                  </Box>
                </TimelineItem>
              )
            })
            }
          </Timeline>
        </Stack>
      </Box >
    </>
  )
}

export default StockDetails