import React, { useState, useEffect } from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Stack, debounce } from '@mui/material'
import { CloseIcon } from '../../components/Constants';
import CommonButton from '../../components/common/Button';
import CommonAutocomplete from '../../components/common/Autocomplete';
import CommonInput from '../../components/common/Input';
import { productDropDown } from '../../Service/product.service'
import { MovementAdd, updateMovement, movementDetail } from '../../Service/Movement.service';
import { addProductTrackingValidationSchema } from '../../helper/validation';
import { addProductTrackFormInitialValues } from '../../helper/initialValues';
import { SOMETHING_WENT_WRONG, USER_CREATE, USER_UPDATE } from '../../components/common/Text';
import { useFormik } from 'formik';
import Notification from '../../components/common/Notification/Notification';
import { getLocation, locationDropDown } from '../../Service/location.service';
import moment from 'moment';
import CommonAutocomplete1 from '../../components/common/Autocomplete1';

const AddProductMovement = ({ handleClose, modalOpen, data, setShow, setIsModalOpen, refresh, setRefresh }) => {

    const [productData, setProductData] = useState([]);
    const [locationData, setLocationData] = useState([]);
    const [show1, setShow1] = useState({
        show: false,
        type: '',
        message: ''
    });
    const productDropDownData = async () => {
        const res = await productDropDown();
        if (res.status == true) {
            setProductData(res.data)
        }
    }

    useEffect(() => {
        productDropDownData();
        getLocationData();
    }, [])

    useEffect(() => {
        if (modalOpen.isEdit) {
            getMovementDetail()
        }
    }, [modalOpen])

    const getMovementDetail = async () => {
        const res = await movementDetail(modalOpen.id);
        if (res.status == true) {
            formik.setValues({
                product_id: res.data.product_id,
                product: res.data.product_id?.id,
                location_id: res.data.location_id,
                location: res.data.location_id?.id,
                move_time: res.data.move_time,
                note: res.data.note
            })
        }
    }


    const getLocationData = async () => {
        const res = await locationDropDown();
        if (res.status == true) {
            setLocationData(res.data)
        }
        else {
            setLocationData([])
        }
    }


    const formik = useFormik({
        initialValues: addProductTrackFormInitialValues,
        onSubmit: async (values) => {
            try {

                let res;
                const commonPayload = {
                    "product_id": values.product_id.id,
                    "location_id": values.location_id.id,
                    "move_time": values.move_time,
                    "note": values.note
                };


                if (modalOpen && modalOpen.isEdit) {
                    const updatePayload = {
                        ...commonPayload,
                    };
                    res = await updateMovement(updatePayload, modalOpen.id);
                } else {
                    res = await MovementAdd(commonPayload);
                }

                if (res.status == true) {
                    data([])
                    formik.resetForm();
                    setShow({
                        show: true,
                        type: 'success',
                        message: res.message || (modalOpen && modalOpen.isEdit ? USER_UPDATE : USER_CREATE),
                    });
                    handleClose()
                    setRefresh(!refresh)
                } else {
                    setShow1({
                        show: true,
                        type: 'error',
                        message: res.message || SOMETHING_WENT_WRONG,
                    });
                }
            } catch (error) {
                setShow1({
                    show: true,
                    type: 'error',
                    message: error.message || SOMETHING_WENT_WRONG,
                });
            }
        },
        validationSchema: addProductTrackingValidationSchema,
    });
    const debouncedOnChange = debounce((e, val) => {
        formik.setFieldValue('product_id', val ? val : '')
        formik.setFieldValue('product', val && val?.id ? val?.id : '')
        formik.touched['product_id'] = val && val?.id ? false : true
    }, 300);

    return (
        <div>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {modalOpen?.isEdit ? 'Edit' : 'Add'} Master Movement
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Box width={{ sm: '400px' }}>
                    <Stack gap={2}>
                        <Box>
                            <CommonAutocomplete1 data={productData} formik={formik} labelinput='Select Product' name1='product_id' name="product" value={formik.values.product_id}
                                onChange={(e, val) => {

                                    debouncedOnChange(e, val)


                                }} />

                        </Box>
                        <Box>
                            <CommonAutocomplete data={locationData} labelinput='Select Location' formik={formik} name1='location_id' name="location" value={formik.values.location_id}
                                onChange={(e, val) => {
                                    formik.setFieldValue('location_id', val)
                                    formik.setFieldValue('location', val && val?.id ? val?.id : '')
                                    formik.touched['location_id'] = val && val?.id ? false : true
                                }} />
                        </Box>

                        <Box>
                            <CommonInput labelinput='Date & Time' type='datetime-local' formik={formik} name='move_time' value={moment(formik.values.move_time).format('YYYY-MM-DDTHH:mm')} />
                        </Box>
                        <Box>
                            <CommonInput labelinput='Note' formik={formik} name='note' value={formik.values.note} />
                        </Box>
                    </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <CommonButton buttonName='cancel' size='small' color='white' onClick={handleClose} />
                <CommonButton buttonName={modalOpen?.isEdit ? 'Save' : 'Add'} size='small' onClick={formik.handleSubmit} />
            </DialogActions>
            <Notification show={show1} setShow={setShow1} />

        </div>
    )
}


export default AddProductMovement