import React from 'react';
import Loader from 'react-dots-loader';
import 'react-dots-loader/index.css';

const Loaders = ({ isOpen }) => {
  const styles = {
    container: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999, // Ensure the loader is on top of other elements
    },
  };
  return isOpen ? (
    <div style={styles.container}>
      <Loader distance={25} size={30} color="#E54E09" />
    </div>
  ) : null;
};



export default Loaders;
