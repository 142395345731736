import React from 'react'
import { Drawer, Grid, Typography, Divider, Stack, IconButton } from '@mui/material';
import { CloseIcon } from '../../helper/Icons'
import CommonInput from '../../components/common/Input';
import CommonButton from '../../components/common/Button';
import CommonSelect from '../../components/common/Select';

const AddFilter = ({ openDrawer, toggleDrawer, formik, setUserListData }) => {
    const drawerWidth = 250;
    return (
        <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer}
            sx={{
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, padding: 2, justifyContent: 'space-between' },
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Typography fontWeight={600} fontSize={'20px'}>Filter</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={toggleDrawer}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={12} marginY={'5px'}>
                    <Divider sx={{ borderColor: "border.main", }} />
                </Grid>
                <Grid item xs={12} marginY={2}>
                    <Grid container gap={2}>
                        <Grid item xs={12}>
                            <CommonSelect
                                labelSelect='Select Status'
                                selectList={[{ value: '1', label: 'Active' }, { value: '0', label: 'InActive' }, { value: '2', label: 'Delete' }]}
                                name='status'
                                selectHead='None'
                                value={formik.values.status}
                                formik={formik}
                            />

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Stack flexDirection={'row'} gap={1}>
                        <CommonButton buttonName='Reset' style={{ width: '100%' }} onClick={() => {
                            formik.resetForm()
                            formik.handleSubmit()
                            setUserListData([])
                            toggleDrawer()
                        }} />
                        <CommonButton buttonName='Filter' style={{ width: '100%' }} onClick={() => {
                            setUserListData([])
                            formik.handleSubmit()
                            toggleDrawer()
                        }} />
                    </Stack>
                </Grid>
            </Grid>
        </Drawer>
    )
}

export default AddFilter