import React, { useEffect, useState } from 'react'
import { Drawer, Grid, Typography, Divider, Stack, IconButton, debounce } from '@mui/material';
import { CloseIcon } from '../../helper/Icons'
import CommonButton from '../../components/common/Button';
import CommonAutocomplete from '../../components/common/Autocomplete';
import { productDropDown } from '../../Service/product.service';
import CommonAutocomplete1 from '../../components/common/Autocomplete1';
import CommonInput from '../../components/common/Input';

const Filter = ({ openDrawer, toggleDrawer, formik, setProductListData }) => {
  const drawerWidth = 250;
  const [productlist, setProductList] = useState([]);

  useEffect(() => {
    productDropdownData()
  }, [])

  const productDropdownData = async () => {
    const res = await productDropDown()
    if (res.status === true) {
      setProductList(res.data)
    }
  }

  const debouncedOnChange = debounce((e, val) => {
    formik.setFieldValue('product', val ? val : '')
    formik.setFieldValue('productId', val && val.id ? val.id : '')
    formik.setFieldTouched('product', val && val.id ? false : true);
  }, 300);

  return (
    <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer}
      sx={{
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, padding: 2, justifyContent: 'space-between' },
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography fontWeight={600} fontSize={'20px'}>Filter</Typography>
          <IconButton
            aria-label="close"
            onClick={toggleDrawer}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} marginY={'5px'}>
          <Divider sx={{ borderColor: "border.main", }} />
        </Grid>
        <Grid item xs={12} marginY={2}>
          <Grid container gap={2}>


            <CommonAutocomplete1
              data={productlist}
              labelinput='Select Product'
              onChange={(e, val) => {

                debouncedOnChange(e, val)

              }}
              value={formik.values.product}
              formik={formik}
              name='productId'
              name1='product'
            />



          </Grid>


        </Grid>
        <Grid item xs={12} marginY={2}>
          <Grid container gap={2}>
            <CommonInput
              name='rackNo'
              formik={formik}
              labelinput='Rack No.'
              placeholder='Rack No.'
              value={formik.values.rackNo}
            />

          </Grid>
        </Grid>

      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Stack flexDirection={'row'} gap={1}>
            <CommonButton buttonName='Reset' style={{ width: '100%' }} onClick={() => {
              // setProductListData([])
              formik.resetForm()
              formik.handleSubmit()
              toggleDrawer()
            }} />
            <CommonButton buttonName='Filter' style={{ width: '100%' }} onClick={() => {
              formik.handleSubmit()
              toggleDrawer()
            }} />
          </Stack>
        </Grid>
      </Grid>
    </Drawer>
  )
}

export default Filter