import { get, post, postToken, reload, put, deleteRequest } from "../web.request";


export const ProductList = async (query) => {
    return await get(`/productList?${query}`);
}
export const productDropDown = async () => {
    return await get(`/productDropdown`);
}

export const addProduct = async (payload) => {
    return await post(`/addProduct`, payload);
}
export const updateProduct = async (payload, id) => {
    return await put(`/updateProduct/${id}`, payload);
}
export const deleteProduct = async (id) => {
    return await deleteRequest(`/deleteProduct/${id}`);
}
export const getProduct = async (id) => {
    return await get(`/adminProductDetail/${id}`);
}
export const productStatus = async (id) => {
    return await put(`/statusChangeProduct/${id}`);
}

export const getProductDetails = async (id) => {
    return await get(`/productDetail/${id}`);
}
export const stockproductDropdown = async () => {
    return await get(`/stockproductDropdown`);
}

export const exportMasterStock = async (query) => {
    return await get(`/exportMasterStock?${query}`);
}

