import React, { useState, useEffect } from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Stack, } from '@mui/material'
import { CloseIcon } from '../../../components/Constants';
import CommonButton from '../../../components/common/Button';
import CommonInput from '../../../components/common/Input';
import { addNewSampleRequest, updateSampleRequest, sampleRequestDetail } from '../../../Service/sample.service';
import { useFormik } from 'formik';
import { addNewRequestInitialValues } from '../../../helper/initialValues';
import { addNewRequestValidationSchema } from '../../../helper/validation';
import Notification from '../../../components/common/Notification/Notification';

const NewReq = ({ handleClose, modalOpen, data, setShow, refresh, setRefresh }) => {
  const [show1, setShow1] = useState({
    show: false,
    type: '',
    message: ''
  });
  const [disableButton, setDisablebutton] = useState(false)

  const formik = useFormik({
    initialValues: addNewRequestInitialValues,
    onSubmit: async (values) => {
      try {
        let res;
        setDisablebutton(true);
        const payload = {
          "customerName": values.customerName,
          "productName": values.productName,
          "poNo": values.poNumber,
          "random": values.random,
          "finish": values.finish,
          "size": values.size,
          "sampleDescription": values.description,
        }


        if (modalOpen && modalOpen.isEdit) {

          res = await updateSampleRequest(payload, modalOpen.id);
        } else {
          res = await addNewSampleRequest(payload);
        }

        if (res.status == true) {
          data([])
          formik.resetForm();
          setShow({
            show: true,
            type: 'success',
            message: (modalOpen && modalOpen.isEdit ? "Sample Request Updated Successfully" : "Sample Request Added Successfully")
          });
          handleClose()
          setRefresh(!refresh)
          setDisablebutton(false)
        } else {
          setShow1({
            show: true,
            type: 'error',
            message: res.message || "Something went wrong!"
          });
          setDisablebutton(false)

        }

      } catch (error) {
        setShow1({
          show: true,
          type: 'error',
          message: error.message || "Something went wrong!"
        });
        setDisablebutton(false)

      }
    },
    validationSchema: addNewRequestValidationSchema,
  });

  const getSampleRequestData = async () => {
    const res = await sampleRequestDetail(modalOpen.id);
    if (res.status == true) {
      formik.setFieldValue('productName', res.data.productName)
      formik.setFieldValue('customerName', res.data.customerName)
      formik.setFieldValue('poNumber', res.data.poNo)
      formik.setFieldValue('size', res.data.size)
      formik.setFieldValue('random', res.data.random)
      formik.setFieldValue('finish', res.data.finish)
      formik.setFieldValue('description', res.data.sampleDescription)

    }
  }

  useEffect(() => {
    if (modalOpen?.isEdit) {
      getSampleRequestData();
    }
  }, [modalOpen?.isEdit])


  return (
    <div>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen?.isEdit ? 'Edit' : 'Add'} New Request
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box width={{ sm: '500px' }} >
          <Stack gap={2}>

            <Box>
              <CommonInput labelinput='Product Name'
                formik={formik}
                name="productName"
                value={formik.values.productName}
              />

            </Box>

            <Box>
              <CommonInput labelinput='Customert Name'
                formik={formik}
                name="customerName"
                value={formik.values.customerName}
                maxLength={100}
              />
            </Box>
            <Box>
              <CommonInput labelinput='PO Number'
                formik={formik}
                name="poNumber"
                value={formik.values.poNumber}
                maxLength={20}
              />
            </Box>
            <Box>
              <CommonInput labelinput='Size'
                formik={formik}
                name="size"
                value={formik.values.size}
                maxLength={10}
              />
            </Box>


            <Box>
              <CommonInput labelinput='Random'
                formik={formik}
                name="random"
                value={formik.values.random}
                maxLength={10}
              />
            </Box>
            <Box>
              <CommonInput labelinput='Finish'
                formik={formik}
                name="finish"
                value={formik.values.finish}
                maxLength={100}
              />
            </Box>
            <Box>
              <CommonInput labelinput='Description'
                formik={formik}
                name="description"
                value={formik.values.description}
                maxLength={200}
              />
            </Box>


          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <CommonButton buttonName='cancel' size='small' color='white' onClick={handleClose} />
        <CommonButton disabled={disableButton} buttonName={modalOpen?.isEdit ? 'Save' : 'Add'} size='small' onClick={formik.handleSubmit} />
      </DialogActions>
      <Notification show={show1} setShow={setShow1} />
    </div>
  )
}

export default NewReq