import { get, post, postToken, reload, put, deleteRequest } from "../web.request";


export const StoreUserBrowserId = async (payload) => {
  return await post(`/addSubscription`, payload);
}

export const getNotificationList = async (id, query) => {
  return await get(`/getNotification/${id}?${query}`);
}

export const markAllRead = async (id) => {
  return await get(`/ReadAllNotification/${id}`);
}

export const notificationRead = async (id) => {
  return await post(`/readNotification/${id}`);
}

//getUnreadNotification
export const getUnreadNotification = async () => {
  return await get(`/getUnreadNotification`);
}

export const markNotificationAsRead = async (id) => {
  return await post(`/readNotification/${id}`);
}
