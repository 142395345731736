import React, { useEffect, useState } from 'react';
import PageHeading from '../../components/common/PageHeading';
import { useStyles } from '../../helper/Theme';
import {
  Grid, Stack, TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { styled } from "@mui/material/styles";
import moment from "moment";
import Skeleton from '@mui/material/Skeleton';
import TableSearch from '../../components/common/Table/TableSearch';
import Pagination from "../../components/common/Table/Pagination";
import { getNotificationList, markNotificationAsRead } from '../../Service/notification.service';
import { useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const Notifications = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [notificationListData, setNotificationListData] = useState([]);
  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const getLocationList = async () => {
    let query = `page=${page}&limit=${pagePerSize}&search=${search}`
    const res = await getNotificationList(1, query);
    if (res.status) {
      setNotificationListData(res?.data)
      setTotalRecords(res?.total || 0);
      setTotalPages(Math.ceil(res?.total / pagePerSize));

    } else {
      setNotificationListData(null)
      setTotalRecords(0);
      setTotalPages(0);
    }
  }

  const markAsRead = async (id, productId) => {
    const res = await markNotificationAsRead(id);
    if (res.status) {
      navigate(`/product-movement/details`, { state: { id: productId } })
      // getLocationList();
    }
  }


  const getSearchKeywords = (e) => {
    setSearch(e.target.value);
  }

  const TableRowsLoader = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
      </TableRow>
    ));
  };

  useEffect(() => {
    getLocationList(page, search);
  }, [page, search])

  return (
    <>
      <PageHeading heading='Notification' />
      <Stack alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: '8px' }}>
        {/* <TableSearch value={search} onChange={getSearchKeywords} /> */}
        <Grid container justifyContent='flex-end' alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit">
        </Grid>
      </Stack>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Title
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Product Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Description
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                date
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notificationListData?.map((row, index) => {
              var {
                title,
                description,
                productName,
              } = row;
              return (
                <TableRow
                  key={index}
                  onClick={() => {
                    markAsRead(row.id, row.productId);
                  }}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 }, background: row.isRead == 0 ? '#e54e0950' : ''
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell component="th" scope="row">
                    {title}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {productName}
                  </TableCell>
                  <TableCell>{description}</TableCell>
                  <TableCell>
                    {moment(row.createdAt).format("LLL")}
                  </TableCell>
                </TableRow>
              );
            })}
            {notificationListData === null ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  No records to display
                </TableCell>
              </TableRow>

            ) : (
              notificationListData.length === 0 ? (<TableRowsLoader rowsNum={10} />) : ("")
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={page}
        onChange={handleChangePage}
        color="primary"
        sx={{ marginTop: '20px', textAlign: 'center' }}
        data={notificationListData}
        totalRecords={totalRecords}
      />
    </>
  )
}

export default Notifications