import React, { useState, useEffect } from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Stack, } from '@mui/material'
import { CloseIcon } from '../../../components/Constants';
import CommonButton from '../../../components/common/Button';
import CommonAutocomplete from '../../../components/common/Autocomplete';
import CommonInput from '../../../components/common/Input';
import { useFormik } from 'formik';
import { addLocationFormInitialValues } from '../../../helper/initialValues';
import { SOMETHING_WENT_WRONG, USER_CREATE, USER_UPDATE } from '../../../components/common/Text';
import { addLocationValidationSchema } from '../../../helper/validation';
import { addLocation, cityDropDown, countryDropDown, stateDropDown, getLocation, updateLocation } from '../../../Service/location.service';
import Notification from '../../../components/common/Notification/Notification';

const LocationAdd = ({ handleClose, modalOpen, setShow, setIsModalOpen, refresh, setRefresh, data }) => {

    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [show1, setShow1] = useState({
        show: false,
        type: '',
        message: ''
    });

    useEffect(() => {
        getCountryData()
    }, [])

    useEffect(() => {
        if (modalOpen && modalOpen.isEdit) {
            getLocationData(modalOpen.id)
        }
    }, [modalOpen])

    const getLocationData = async (id) => {
        const res = await getLocation(id)
        if (res.status === true) {
            formik.setFieldValue('name', res.data.name)
            formik.setFieldValue('contact', res.data.contact)
            formik.setFieldValue('address', res.data.address)
            formik.setFieldValue('zip', res.data.pincode)
            formik.setFieldValue('country', res.data.country_id)
            formik.setFieldValue('state', res.data.state_id)
            formik.setFieldValue('city', res.data.city_id)
            formik.setFieldValue('date', res.data.date)
            formik.setFieldValue('countryId', res.data.country_id?.id)
            formik.setFieldValue('stateId', res.data.state_id?.id)
            formik.setFieldValue('cityId', res.data.city_id?.id)
        }
    }




    const formik = useFormik({
        initialValues: addLocationFormInitialValues,
        onSubmit: async (values) => {
            try {
                let res;
                const commonPayload = {
                    "name": values.name,
                    "contact": values.contact,
                    "country_id": values.countryId,
                    "state_id": values.stateId,
                    "city_id": values.cityId,
                    "address": values.address,
                    "pincode": values.zip,
                };


                if (modalOpen && modalOpen.isEdit) {
                    const updatePayload = {
                        ...commonPayload,
                    };
                    res = await updateLocation(updatePayload, modalOpen.id);
                } else {
                    res = await addLocation(commonPayload);
                }
                if (res.status == true) {
                    data([])
                    formik.resetForm();
                    setShow({
                        show: true,
                        type: 'success',
                        message: res.message || (modalOpen && modalOpen.isEdit ? USER_UPDATE : USER_CREATE),
                    });
                    handleClose()
                    setRefresh(!refresh)
                } else {
                    setShow1({
                        show: true,
                        type: 'error',
                        message: res.message || SOMETHING_WENT_WRONG,
                    });
                }
            } catch (error) {
                setShow1({
                    show: true,
                    type: 'error',
                    message: error.message || SOMETHING_WENT_WRONG,
                });
            }
        },
        validationSchema: addLocationValidationSchema,
    });

    const getCountryData = async (id) => {
        const res = await countryDropDown()
        if (res.status === true) {
            setCountryList(res.data)
        }
        else {
            setCountryList([])
        }
    }

    const getStateData = async (id) => {
        const res = await stateDropDown(id)
        if (res.status === true) {
            setStateList(res.data)
        }
        else {
            setStateList([])
        }
    }

    const getCityData = async (id) => {
        const res = await cityDropDown(id)
        if (res.status === true) {
            setCityList(res.data)
        }
        else {
            setCityList([])
        }
    }

    useEffect(() => {
        if (formik.values.country) {
            getStateData(formik.values.country.id)
        }
    }
        , [formik.values.country])

    useEffect(() => {
        if (formik.values.state) {
            getCityData(formik.values.state?.id)
        }
    }
        , [formik.values.state])

    return (
        <div>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {modalOpen?.isEdit ? 'Edit' : 'Add'} Location
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Box width={{ sm: '500px' }} >
                    <Stack gap={2}>
                        <Box>
                            <CommonInput labelinput='Name' formik={formik} name='name' maxLength={30} />
                        </Box>
                        <Box>
                            <CommonInput labelinput='Contact' formik={formik} name='contact' maxLength={15} />
                        </Box>
                        <Box>
                            <CommonInput labelinput='Address' formik={formik} name='address' maxLength={100} />
                        </Box>
                        <Box>
                            <CommonAutocomplete
                                data={countryList}
                                labelinput='Select Country'
                                onChange={(e, val) => {
                                    formik.setFieldValue('country', val ? val : '')
                                    formik.setFieldValue('state', '')
                                    formik.setFieldValue('city', '')
                                    formik.setFieldValue('countryId', val && val.id ? val.id : '')
                                    formik.setFieldValue('stateId', '')
                                    formik.setFieldValue('cityId', '')
                                    formik.setFieldTouched('country', val && val.id ? false : true);
                                }}
                                value={formik.values.country}
                                formik={formik}
                                name='countryId'
                                name1='country'

                            />
                        </Box>
                        <Box>
                            <CommonAutocomplete
                                data={stateList}
                                labelinput='Select State'
                                onChange={(e, val) => {
                                    formik.setFieldValue('state', val ? val : '')

                                    formik.setFieldValue('city', '')
                                    formik.setFieldValue('stateId', val && val.id ? val.id : '')
                                    formik.setFieldValue('cityId', '')
                                    formik.touched['state'] = val && val.id ? false : true

                                }}
                                value={formik.values.state}
                                formik={formik}
                                name='stateId'
                                name1='state'
                            />
                        </Box>
                        <Box>
                            <CommonAutocomplete
                                data={cityList}
                                labelinput='Select City'
                                onChange={(e, val) => {
                                    formik.touched['city'] = val && val.id ? false : true
                                    formik.setFieldValue('city', val ? val : '')
                                    formik.setFieldValue('cityId', val && val.id ? val.id : '')

                                }}
                                value={formik.values.city}
                                formik={formik}
                                name='cityId'
                                name1='city'
                            />
                        </Box>
                        <Box>
                            <CommonInput labelinput='Pincode' formik={formik} name='zip' maxLength={6} />
                        </Box>
                    </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <CommonButton buttonName='cancel' size='small' color='white' onClick={handleClose} />
                <CommonButton buttonName={modalOpen?.isEdit ? 'Save' : 'Add'} size='small' type="submit" onClick={formik.handleSubmit} />
            </DialogActions>

            <Notification show={show1} setShow={setShow1} />

        </div>
    )
}

export default LocationAdd