import React from 'react';
import { MenuItem, Select, Typography } from '@mui/material';

export default function CommonSelect(props) {
    const { selectHead, selectList, labelSelect, name, style, className, disabled, formik } = props;

    return (
        <>
            {labelSelect && <Typography fontSize={'16px'} fontWeight={500} mb={'2px'}>{labelSelect}</Typography>}
            <Select
                name={name} // Set the 'name' attribute for formik
                value={formik.values[name]} // Use formik's field value
                onChange={formik.handleChange} // Use formik's handleChange
                displayEmpty
                fullWidth
                sx={style}
                className={className}
                disabled={disabled}
            >
                {selectHead && <MenuItem value="" disabled>{selectHead}</MenuItem>}
                {selectList.map((data, i) => (
                    <MenuItem key={i} value={data.value}>
                        {data.label || data.name}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
}
