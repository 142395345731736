export const loginInitialValues = {
    email: "",
    password: "",
}
export const superloginInitialValues = {
    user: "",
    password: "",
}

export const profileInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    contact: "",
}

export const changePasswordInitialValues = {
    newPassword: "",
    confirmpass: ""
}

export const addUserFormInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    contact: "",
}

export const updateUserFormInitialValues = {
    firstName: "",
    lastName: "",
    company_name: "",
    contract_join_date: "",
    contract_end_date: "",
}

export const addAdminFormInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
}

export const updateAdminFormInitialValues = {
    firstName: "",
    lastName: "",
    password: "",
}

export const addFilterInitialValues = {
    status: "",
    fromDate: "",
    toDate: "",
    city: "",
    state: "",
    country: "",
    startDate: "",
    endDate: "",
    product: "",
    rackNo: "",
}


export const addLocationFormInitialValues = {
    name: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    contact: "",
    date: "",
    cityId: "",
    stateId: "",
    countryId: "",

}

export const addProductTrackFormInitialValues = {
    product_id: "",
    location_id: "",
    move_time: "",
    note: "",
    product: "",
    location: "",
    startDate: "",
    endDate: "",
}
export const productFilterInitialValues = {
    product_id: "",
    product: "",
    status: "",
}

export const productAddFormInitialValues = {
    productName: "",
    productDescription: "",
    location: "",
    location_id: "",
    random: "",
    rackLocation: "",
    size: "",
    finish: "",
    image: [],
}

export const moveProductInitialValues = {
    location_id: "",
    note: "",
    location: "",
    product_id: "",
    rackLocation: "",
    vehicleNumber: "",
}

export const addNewRequestInitialValues = {
    productName: "",
    customerName: "",
    poNumber: "",
    size: "",
    finish: "",
    random: "",
    description: "",
}

export const addToShippedNewRequestInitialValues = {
    invoiceNumber: "",
    shippingDate: "",
    containerNumber: "",
    notes: "",
}

export const addNewRequestFilterInitialValues = {
    status: '',
}

export const masterAddFormInitialValues = {
    productName: "",
    productDescription: "",
    random: "",
    size: "",
    finish: "",
}

export const convertToProductInitialValues = {
    location: "",
    location_id: "",
    rackLocation: "",
    image: [],
}

export const addStockInitialValues = {
    productId: "",
    product: "",
    quantity: "",
    description: "",
    location: "",
    location_id: "",
    rank: "",
}

export const changePasswordInitialValuess = {
    newPassword: "",
    confirmpass: "",
    currentPassword: ""
}