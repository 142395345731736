import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import PageHeading from '../../../components/common/PageHeading';
import { theme, useStyles } from '../../../helper/Theme';

import {
  Grid, Stack, TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from 'react-router-dom';
import { statusUpdateAddToShippedSampleRequest, statusUpdateReadyToShippedSampleRequest, sampleRequestList } from '../../../Service/sample.service';
import moment from "moment";
import Notification from '../../../components/common/Notification/Notification';
import { EditIcon, AddIcon } from '../../../components/Constants';
import CommonButton from '../../../components/common/Button';
import Skeleton from '@mui/material/Skeleton';
import TableSearch from '../../../components/common/Table/TableSearch';
import { FiFilter } from '../../../helper/Icons';
import CommonModal from '../../../components/common/Modal';
import Pagination from "../../../components/common/Table/Pagination";
import FilterNewRequest from './FilterNewRequest';
import { addNewRequestFilterInitialValues, addToShippedNewRequestInitialValues } from '../../../helper/initialValues';
import { useFormik } from 'formik';
import CommonInput from '../../../components/common/Input';
import { addToShippedValidationSchema } from '../../../helper/validation';
import StatusChangeModal from '../../../components/common/modal/DeleteModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const NewRequest = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [newRequestListData, setNewRequestListData] = useState([]);
  const [page, setPage] = useState(localStorage.getItem("sampleData1") ? JSON.parse(localStorage.getItem("sampleData1")).page : 1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [sampleProductId, setSampleProductData] = useState("");
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: false
  });
  const [handelModal, setHandelModal] = useState({
    open: false,
    type: '',
    message: ''
  });

  const formik = useFormik({
    initialValues: addNewRequestFilterInitialValues,
    onSubmit: async (values) => {
      setRefresh(!refresh)

    },
  });

  const handleCloseModal = () => {
    setHandelModal({
      open: false,
      type: '',
      message: '',
    })
  }



  const formik1 = useFormik({
    initialValues: addToShippedNewRequestInitialValues,
    onSubmit: async (values) => {
      const payload = {
        "invNo": values.invoiceNumber,
        "date": values.shippingDate,
        "shippedDescription": values.notes,
        "container": values.containerNumber,
      };
      const res = await statusUpdateAddToShippedSampleRequest(sampleProductId, payload);

      if (res.status == true) {
        formik1.resetForm();
        setShow({
          show: true,
          type: 'success',
          message: res.message || "Sample Request Shipped Successfully",
        });
        setNewRequestListData([]);
        setOpen(0);
        setRefresh(!refresh)
      } else {
        setShow({
          show: true,
          type: 'error',
          message: res.message || "Something went wrong!"
        });
      }
    },
    validationSchema: addToShippedValidationSchema
  });


  const [show, setShow] = useState({
    show: false,
    type: '',
    message: ''
  });
  const [filterDrawer, setFilterDrawer] = React.useState(false);
  const handleFilterDrawer = () => {
    setFilterDrawer(!filterDrawer);
  };



  const handleChangePage = (event, value) => {
    setNewRequestListData([]);
    setPage(value);
  };

  const getNewRequesttList = async (page, search) => {
    let query = `?page=${page}&limit=${pagePerSize}&search=${search}`;
    if (formik.values.status) {
      query += `&status=${formik.values.status}`
    }

    const res = await sampleRequestList(query);
    if (res.status) {
      setNewRequestListData(res?.data)
      setTotalRecords(res?.total || 0);
      setTotalPages(Math.ceil(res?.total / pagePerSize));
    } else {
      setNewRequestListData(null)
      setTotalRecords(0);
      setTotalPages(0);
    }
  }

  const getSearchKeywords = (e) => {
    setSearch(e.target.value);
  }


  const TableRowsLoader = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
      </TableRow>
    ));
  };

  const confirmStatusChnage = async () => {
    const res = await statusUpdateReadyToShippedSampleRequest(handelModal.id);
    if (res.status) {
      setShow({
        show: true,
        type: 'success',
        message: res.message || "Sample Request Shipped Successfully",
      });
      setNewRequestListData([]);
      setOpen(0);
      setRefresh(!refresh)
      handleCloseModal();
    } else {
      setShow({
        show: true,
        type: 'error',
        message: res.message || "Something went wrong!"
      });
    }
  }

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("sampleData1"));

    if (data) {
      setTotalRecords(0);
      setPage(data.page);
      if (data.search) {
        setSearch(data.search);
      }
      if (data.values) {
        formik.setValues(data.values);
        formik.handleSubmit();
      }
      localStorage.removeItem("sampleData1");
    } else {
      getNewRequesttList(page, search);
    }
  }, [refresh, search, page]);

  const navigationHandel = (id) => {
    localStorage.setItem("sampleData1", JSON.stringify({
      page: page,
      search: search,
      values: formik.values,
      tab: "newRequest"

    }));
    localStorage.setItem("tab", JSON.stringify({
      tab: "newRequest"
    }));
    navigate(`/sample-management/details`, { state: { id: id } })
  }

  return (
    <>

      <Stack alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: '8px' }}>
        <TableSearch value={search} onChange={getSearchKeywords} />
        <Grid container justifyContent='flex-end' alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit">
          <Grid item>
            <CommonButton buttonName={<AddIcon sx={{ fontSize: '22px' }} />} onClick={() => setIsModalOpen({ open: true, currentComponent: "addNewReq", isEdit: false })} />
          </Grid>
          <Grid item>
            <CommonButton buttonName={<FiFilter style={{ fontSize: '22px' }} />} onClick={handleFilterDrawer} />
          </Grid>
          <FilterNewRequest setNewRequestListData={setNewRequestListData} formik={formik} openDrawer={filterDrawer} toggleDrawer={handleFilterDrawer} />
        </Grid>
      </Stack>


      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                PO Number
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Customer Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Size
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Product Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Shipment
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Created Date
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Action
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newRequestListData?.map((row, index) => {
              var {
                id,
                productName,
                customerName,
                poNo,
                size,
                status
              } = row;
              return (
                <>
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => {
                      { status != 0 ? setOpen(open === id ? 0 : id) : navigationHandel(row.id) }
                      setOpen(open === id ? 0 : id);
                      formik1.resetForm();
                      setSampleProductData(row.id);
                      {
                        status == 0 && localStorage.setItem("sampleData1", JSON.stringify({
                          page: page,
                          search: search,
                          values: formik.values,
                        }));
                        localStorage.setItem("tab", JSON.stringify({
                          tab: "newRequest"
                        }));
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>{poNo}</TableCell>
                    <TableCell>{customerName}</TableCell>
                    <TableCell>{size}</TableCell>
                    <TableCell component="th" scope="row">
                      {productName.length > 20 ? `${productName.substring(0, 20)}...` : productName}
                    </TableCell>
                    <TableCell>
                      {
                        row.status === 0 ? (
                          <CommonButton
                            disabled={row.status === 0 ? false : true}
                            buttonName={row.status === 0 ? 'Pending' : 'Ready to Ship'}
                            fontSize='1rem' minWidth='40px' padding='6px 8px' onClick={(e) => {
                              e.stopPropagation();
                              setHandelModal({
                                open: true,
                                type: 'status',
                                message: 'Are you sure you want to change the status to Ready to Ship?',
                                id: id
                              })
                            }} />
                        ) : (
                          <div style={{ color: "black", cursor: "pointer", backgroundColor: "#d6fcd2", display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '5px 10px', borderRadius: '7px' }} >
                            {row.status === 0 ? 'Pending' : 'Ready to Ship'}
                          </div>
                        )
                      }



                    </TableCell>
                    <TableCell onClick={(e) => { e.stopPropagation(); }}>
                      {moment(row.createdAt).format("LLL")}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'end' }} onClick={(e) => { e.stopPropagation() }}>
                      <Grid container spacing={2} alignItems="center" flexWrap="nowrap">
                        <Grid item>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={(e) => {
                              navigate(`/sample-management/details`, { state: { id: row.id } })
                              localStorage.setItem("sampleData1", JSON.stringify({
                                page: page,
                                search: search,
                                values: formik.values,
                              }));
                              localStorage.setItem("tab", JSON.stringify({
                                tab: "newRequest"
                              }));
                            }}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 1024 1024"><path fill="black" d="M512 160c320 0 512 352 512 352S832 864 512 864S0 512 0 512s192-352 512-352m0 64c-225.28 0-384.128 208.064-436.8 288c52.608 79.872 211.456 288 436.8 288c225.28 0 384.128-208.064 436.8-288c-52.608-79.872-211.456-288-436.8-288m0 64a224 224 0 1 1 0 448a224 224 0 0 1 0-448m0 64a160.192 160.192 0 0 0-160 160c0 88.192 71.744 160 160 160s160-71.808 160-160s-71.744-160-160-160" /></svg>
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <EditIcon onClick={() => setIsModalOpen({ open: true, currentComponent: "addNewReq", isEdit: true, id: id })} sx={{ width: '20px', height: '20px' }} />
                        </Grid>

                      </Grid>
                    </TableCell>
                  </TableRow >
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} className='collapse_row'>
                      <Collapse in={open === id}
                        timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 2 }}>
                          <Grid container gap={{ xs: 2 }} alignItems={'flex-start'} justifyContent={{ sm: 'center' }}>
                            <Grid item xs={10.2}>
                              <Typography fontWeight={'700'} fontSize={'20px'} mb={2}>
                                Shipp Product
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={5}>
                              <CommonInput labelinput='Invoice No'
                                formik={formik1} name="invoiceNumber" value={formik1.values.invoiceNumber} maxLength={20} />
                            </Grid>
                            <Grid item xs={6} sm={5}>
                              <CommonInput labelinput='Container No' formik={formik1} name="containerNumber" value={formik1.values.containerNumber} maxLength={20} />
                            </Grid>
                            <Grid item xs={6} sm={5}>
                              <CommonInput labelinput='Date' formik={formik1} name="shippingDate" value={formik1.values.shippingDate} type="date" />
                            </Grid>
                            <Grid item xs={6} sm={5}>
                              <CommonInput labelinput='Notes' formik={formik1} name="notes" value={formik1.values.notes} maxLength={150} />
                            </Grid>
                            <Grid item xs={10.2}>
                              <CommonButton buttonName={'Save'} className='move_product' onClick={formik1.handleSubmit} />
                            </Grid>
                          </Grid>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
            {
              newRequestListData === null ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    No records to display
                  </TableCell>
                </TableRow>

              ) : (
                newRequestListData.length === 0 ? (<TableRowsLoader rowsNum={10} />) : ("")
              )
            }
          </TableBody >
        </Table >
      </TableContainer >

      <Pagination
        count={totalPages}
        page={page}
        onChange={handleChangePage}
        color="primary"
        sx={{ marginTop: '20px', textAlign: 'center' }}
        data={newRequestListData}
        totalRecords={totalRecords}
      />
      <StatusChangeModal
        handleAction={confirmStatusChnage}
        handleCloseModal={handleCloseModal}
        setHandelModal={setHandelModal}
        handelModal={handelModal}
      />

      <CommonModal
        modalOpen={isModalOpen}
        setShow={setShow}
        setRefresh={setRefresh}
        refresh={refresh}
        data={setNewRequestListData}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: false })
        } />


      <Notification show={show} setShow={setShow} />
    </>
  )
}

export default NewRequest