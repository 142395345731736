import React, { useEffect, useState } from 'react';
import { useStyles } from '../../../helper/Theme';
import {
  Grid, Stack, TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from '@mui/material';
import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import { sampleRequestList } from '../../../Service/sample.service';
import moment from "moment";
import Skeleton from '@mui/material/Skeleton';
import TableSearch from '../../../components/common/Table/TableSearch';
import Pagination from "../../../components/common/Table/Pagination";
import CommonModal from '../../../components/common/Modal';
import { EditIcon } from '../../../components/Constants';
import { Tab } from 'bootstrap';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const Shipped = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [newRequestListData, setNewRequestListData] = useState([]);
  const [page, setPage] = useState(localStorage.getItem("sampleData") ? JSON.parse(localStorage.getItem("sampleData")).page : 1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: false
  });

  const [show, setShow] = useState({
    show: false,
    type: '',
    message: ''
  });

  const handleChangePage = (event, value) => {
    setNewRequestListData([]);
    setPage(value);
  };

  const getNewRequesttList = async (page, search) => {
    setNewRequestListData([]);
    let query = `?page=${page}&limit=${pagePerSize}&search=${search}&status=2`;

    const res = await sampleRequestList(query);
    if (res.status) {
      setNewRequestListData(res?.data)
      setTotalRecords(res?.total || 0);
      setTotalPages(Math.ceil(res?.total / pagePerSize));

    } else {
      setNewRequestListData(null)
      setTotalRecords(0);
      setTotalPages(0);
    }
  }

  const getSearchKeywords = (e) => {
    setSearch(e.target.value);
  }


  const TableRowsLoader = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
      </TableRow>
    ));
  };


  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("sampleData"));

    if (data) {
      setTotalRecords(0);
      setPage(data.page);
      if (data.search) {
        setSearch(data.search);
      }
      localStorage.removeItem("sampleData");
    } else {
      getNewRequesttList(page, search);
    }
  }, [refresh, search, page]);

  return (
    <>

      <Stack alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: '8px' }}>
        <TableSearch value={search} onChange={getSearchKeywords} />
        <Grid container justifyContent='flex-end' alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit">


        </Grid>
      </Stack>


      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Product Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Customer Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                PO Number
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Size
              </StyledTableCell>


              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Created Date
              </StyledTableCell>

              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newRequestListData?.map((row, index) => {
              var {
                id,
                productName,
                customerName,
                poNo,
                size,
                status
              } = row;
              return (
                <>
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => {
                      navigate(`/sample-management/details`, { state: { id: row.id } })
                      localStorage.setItem("sampleData", JSON.stringify({
                        page: page,
                        search: search,
                      }));
                      localStorage.setItem("tab", JSON.stringify({

                        tab: "shipped"
                      }));

                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell component="th" scope="row">
                      {productName.length > 20 ? `${productName.substring(0, 20)}...` : productName}
                    </TableCell>
                    <TableCell>{customerName}</TableCell>
                    <TableCell>{poNo}</TableCell>
                    <TableCell>{size}</TableCell>

                    <TableCell onClick={(e) => { e.stopPropagation(); }}>
                      {moment(row.createdAt).format("LLL")}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'end' }} onClick={(e) => { e.stopPropagation() }}>
                      <Grid container spacing={2} alignItems="center" flexWrap="nowrap">
                        <Grid item>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={(e) => {
                              navigate(`/sample-management/details`, { state: { id: row.id } })
                              localStorage.setItem("sampleData", JSON.stringify({
                                page: page,
                                search: search,
                                tab: "shipped"

                              }));
                              localStorage.setItem("tab", JSON.stringify({

                                tab: "shipped"
                              }));
                            }
                            }
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 1024 1024"><path fill="black" d="M512 160c320 0 512 352 512 352S832 864 512 864S0 512 0 512s192-352 512-352m0 64c-225.28 0-384.128 208.064-436.8 288c52.608 79.872 211.456 288 436.8 288c225.28 0 384.128-208.064 436.8-288c-52.608-79.872-211.456-288-436.8-288m0 64a224 224 0 1 1 0 448a224 224 0 0 1 0-448m0 64a160.192 160.192 0 0 0-160 160c0 88.192 71.744 160 160 160s160-71.808 160-160s-71.744-160-160-160" /></svg>
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <EditIcon onClick={() => setIsModalOpen({ open: true, currentComponent: "addNewReq", isEdit: true, id: id })} sx={{ width: '20px', height: '20px' }} />
                        </Grid>

                      </Grid>
                    </TableCell>
                  </TableRow >

                </>
              );
            })}
            {
              newRequestListData === null ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    No records to display
                  </TableCell>
                </TableRow>

              ) : (
                newRequestListData.length === 0 ? (<TableRowsLoader rowsNum={10} />) : ("")
              )
            }
          </TableBody >
        </Table >
      </TableContainer >

      <Pagination
        count={totalPages}
        page={page}
        onChange={handleChangePage}
        color="primary"
        sx={{ marginTop: '20px', textAlign: 'center' }}
        data={newRequestListData}
        totalRecords={totalRecords}
      />

      <CommonModal
        modalOpen={isModalOpen}
        setShow={setShow}
        setRefresh={setRefresh}
        refresh={refresh}
        data={setNewRequestListData}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: false })
        } />


    </>
  )
}

export default Shipped