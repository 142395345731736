import React, { useState, useEffect } from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Stack, } from '@mui/material'
import { CloseIcon } from '../../../components/Constants';
import CommonButton from '../../../components/common/Button';
import CommonAutocomplete from '../../../components/common/Autocomplete';
import CommonInput from '../../../components/common/Input';
import FileUpload from '../../../components/common/FileUpload';
import { convertToProduct } from '../../../Service/master.service';
import { useFormik } from 'formik';
import { convertToProductInitialValues } from '../../../helper/initialValues';
import { convertToProductValidationSchema } from '../../../helper/validation';
import Notification from '../../../components/common/Notification/Notification';
import { locationDropDown } from '../../../Service/location.service';

const ConvertToProduct = ({ handleClose, modalOpen, data, setShow, setIsModalOpen, refresh, setRefresh }) => {
  const [show1, setShow1] = useState({
    show: false,
    type: '',
    message: ''
  });
  const [files, setFiles] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [deleteImage, setDeleteImage] = useState([])

  const formik = useFormik({
    initialValues: convertToProductInitialValues,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        files.map((file) => {
          if (file.id == undefined) {
            formData.append('image', file.image);
          }
        })
        formData.append('location_id', values.location);
        formData.append('rackLocation', values.rackLocation);
        formData.append('specifications', "");
        if (modalOpen.isEdit) {
          formData.append('deletedImageIds', JSON.stringify(deleteImage));
        }


        let res = await convertToProduct(modalOpen.id, formData);

        if (files.length > 5) {
          setShow1({
            show: true,
            type: 'error',
            message: "You can only upload up to 5 images" || "Something went wrong!"
          });
        } else {
          if (res.status == true) {
            data([])
            formik.resetForm();
            setShow({
              show: true,
              type: 'success',
              message: res.message || "Master Converted to Product Successfully"
            });
            handleClose()
            setRefresh(!refresh)
          } else {
            setShow1({
              show: true,
              type: 'error',
              message: res.message || "Something went wrong!"
            });
          }
        }
      } catch (error) {
        setShow1({
          show: true,
          type: 'error',
          message: error.message || "Something went wrong!"
        });
      }
    },
    validationSchema: convertToProductValidationSchema,
  });

  useEffect(() => {
    getLocationData();
  }, [])

  const getLocationData = async () => {
    const res = await locationDropDown();
    if (res.status == true) {
      setLocationData(res.data)
    }
    else {
      setLocationData([])
    }
  }

  return (
    <div>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Convert Master to Product
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box width={{ sm: '500px' }} >
          <Stack gap={2}>
            <Box>
              <FileUpload setShow1={setShow1} setDeleteImage={setDeleteImage} deleteImage={deleteImage} labelinput='Product Image' files={files} setFiles={setFiles} />
            </Box>

            <Box>
              <CommonAutocomplete data={locationData} labelinput='Select Location' formik={formik} name1='location_id' name="location" value={formik.values.location_id}
                onChange={(e, val) => {
                  formik.setFieldValue('location_id', val)
                  formik.setFieldValue('location', val && val?.id ? val?.id : '')
                  formik.touched['location_id'] = val && val?.id ? false : true
                }}
              />
            </Box>


            <Box>
              <CommonInput labelinput='Rack Location'
                formik={formik}
                name="rackLocation"
                value={formik.values.rackLocation}
                maxLength={10}
              />
            </Box>


          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <CommonButton buttonName='cancel' size='small' color='white' onClick={handleClose} />
        <CommonButton buttonName={modalOpen?.isEdit ? 'Save' : 'Add'} size='small' onClick={formik.handleSubmit} />
      </DialogActions>
      <Notification show={show1} setShow={setShow1} />
    </div>
  )
}

export default ConvertToProduct