import React, { useEffect, useState } from 'react';
import { Drawer, Grid, Typography, Divider, Stack, IconButton, debounce } from '@mui/material';
import { CloseIcon } from '../../helper/Icons';
import CommonButton from '../../components/common/Button';
import CommonAutocomplete from '../../components/common/Autocomplete';
import { productDropDown } from '../../Service/product.service';
import CommonSelect from '../../components/common/Select';
import CommonAutocomplete1 from '../../components/common/Autocomplete1';

const AddFilter = ({ openDrawer, formik, toggleDrawer, setProductListData }) => {
    const drawerWidth = 250;
    const [productData, setProductData] = useState([]);

    useEffect(() => {
        getProductDropDownData();
    }, []);

    const getProductDropDownData = async () => {
        const res = await productDropDown();
        setProductData(res.status ? res.data : []);
    };

    const handleFilter = () => {
        setProductListData([]);
        formik.handleSubmit();
        toggleDrawer();
    };

    const handleReset = () => {
        formik.resetForm();
        setProductListData([]);
        formik.handleSubmit();
        toggleDrawer();
    };

    const debouncedOnChange = debounce((e, val) => {
        formik.setFieldValue('product_id', val || '');
        formik.setFieldValue('product', val?.id || '');
        formik.touched['product_id'] = !val?.id;
    }, 300);

    return (
        <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer}
            sx={{
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, padding: 2, justifyContent: 'space-between' },
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Typography fontWeight={600} fontSize={'20px'}>Filter</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={toggleDrawer}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'grey.500',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={12} marginY={'5px'}>
                    <Divider sx={{ borderColor: "border.main", }} />
                </Grid>
                <Grid item xs={12} marginY={2}>
                    <Grid container gap={2}>
                        <Grid item xs={12}>
                            <CommonAutocomplete1
                                data={productData}
                                formik={formik}
                                labelinput='Select Product'
                                name1='product_id'
                                name="product"
                                value={formik.values.product_id}
                                onChange={(e, val) => {

                                    debouncedOnChange(e, val);

                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CommonSelect
                                labelSelect='Select Status'
                                selectList={[{ value: '1', label: 'Active' }, { value: '0', label: 'InActive' }, { value: '2', label: 'Delete' }]}
                                name='status'
                                selectHead='None'
                                value={formik.values.status}
                                formik={formik}
                            />

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Stack flexDirection={'row'} gap={1}>
                        <CommonButton
                            buttonName='Reset'
                            style={{ width: '100%' }}
                            onClick={handleReset}
                        />
                        <CommonButton
                            buttonName='Filter'
                            style={{ width: '100%' }}
                            onClick={handleFilter}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </Drawer>
    );
};

export default AddFilter;
