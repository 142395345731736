import React, { useEffect, useState } from 'react';
import PageHeading from '../../components/common/PageHeading';
import { useStyles } from '../../helper/Theme';
import {
  Grid, Stack, TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from '@mui/material';
import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import { deleteStock, exportSampleStock, stockList } from '../../Service/stock.service';
import moment from "moment";
import Notification from '../../components/common/Notification/Notification';
import { AddIcon, EditIcon } from '../../components/Constants';
import CommonButton from '../../components/common/Button';
import Skeleton from '@mui/material/Skeleton';
import TableSearch from '../../components/common/Table/TableSearch';
import { FiFilter } from '../../helper/Icons';
import Pagination from "../../components/common/Table/Pagination";
import Filter from './Filter';
import { addFilterInitialValues } from '../../helper/initialValues';
import { useFormik } from 'formik';
import CommonModal from '../../components/common/Modal';
import StatusChangeModal from '../../components/common/modal/DeleteModal';
import { DELETE_MESSAGE, STATUS_MESSAGE } from '../../components/common/Text';
import { DeleteIcon } from '../../components/Constants';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const Stock = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [stockListData, setStockListData] = useState([]);
  const [page, setPage] = useState(localStorage.getItem("stock") ? JSON.parse(localStorage.getItem("stock")).page : 1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [filterDrawer, setFilterDrawer] = React.useState(false);
  const [productListData, setProductListData] = useState([]);
  const [handelModal, setHandelModal] = useState({
    open: false,
    type: '',
    message: ''
  });

  const handleCloseModal = () => {
    setHandelModal({
      open: false,
      type: '',
      message: '',
      id: ""
    })
  }

  const [refresh, setRefresh] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: false,
    id: ""
  });

  const [show, setShow] = useState({
    show: false,
    type: '',
    message: ''
  });

  const handleChangePage = (event, value) => {
    setPage(value);
    getStockList(value, search);
  };

  const handleFilterDrawer = () => {
    setFilterDrawer(!filterDrawer);
  };

  const getStockList = async (page, search) => {
    let query = `?page=${page}&limit=${pagePerSize}&search=${search}&product=${formik.values.productId ? formik.values.productId : ''}&rackNo=${formik.values.rackNo ? formik.values.rackNo : ''}`;

    const res = await stockList(query);
    if (res.status) {
      setStockListData(res?.data)
      setTotalRecords(res?.total || 0);
      setTotalPages(Math.ceil(res?.total / pagePerSize));

    } else {
      setStockListData(null)
      setTotalRecords(0);
      setTotalPages(0);
    }
  }

  const confirmDeleteAdmin = async () => {
    if (handelModal.type == "download") {
      handelExport(search);
    }
    if (handelModal.id) {
      let res = await deleteStock(handelModal.id);

      if (res.status == true) {
        setProductListData(res.data)
        setRefresh(!refresh)
        setShow({
          show: true,
          type: 'success',
          message: res.message || "Sample deleted successfully"
        })
        setHandelModal({
          open: false,
          type: '',
          message: '',
          id: ""
        })
      } else {
        setShow({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong'
        })
      }
    }
  }

  const handelExport = async (search) => {
    let query = `&search=${search}&product=${formik.values.productId ? formik.values.productId : ''}&rackNo=${formik.values.rackNo ? formik.values.rackNo : ''}`;
    const res = await exportSampleStock(query);

    const url = window.URL.createObjectURL(new Blob([res.data]));

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'sample-stock.csv');
    document.body.appendChild(link);
    link.click();
    setShow({
      show: true,
      type: 'success',
      message: res.message || 'Exported successfully'
    })
    setHandelModal({
      open: false,
      type: '',
      message: ''
    })

  }
  
  const handleDeleteSample = (id) => {
    if (id) {
      setHandelModal({
        open: true,
        type: 'delete',
        message: 'Are you sure you want to delete this sample?',
        id: id
      })
    }
  }

  const getSearchKeywords = (e) => {
    setSearch(e.target.value);
  }

  const formik = useFormik({
    initialValues: addFilterInitialValues,
    onSubmit: async (values) => {
      setPage(1);
      getStockList(1, search);
    }
  });

  const TableRowsLoader = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
      </TableRow>
    ));
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("stock"));
    if (data) {
      setTotalRecords(0);
      setPage(data.page);
      if (data.search) {
        setSearch(data.search);
      }
      formik.setValues(data.values);
      formik.handleSubmit();
      localStorage.removeItem("stock");
    } else {
      getStockList(1, search);
    }
  }, [refresh, search]);

  return (
    <>
      <PageHeading heading='Sample Stock Management' />
      <Stack alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: '8px' }}>
        <TableSearch value={search} onChange={getSearchKeywords} />
        <Grid container justifyContent='flex-end' alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit">
            <Grid item>
              <CommonButton className='export_button' buttonName={'Export'}
                onClick={() => {
                  setHandelModal({ open: true, type: 'download', message: 'Are you sure you want to export?' })
                }} />
            </Grid>
          <Grid item>
            <CommonButton buttonName={<AddIcon sx={{ fontSize: '22px' }} />} onClick={() => setIsModalOpen({ open: true, currentComponent: "stockAdd", isEdit: false })} />
          </Grid>
          <Grid item>
            <CommonButton buttonName={<FiFilter style={{ fontSize: '22px' }} />} onClick={handleFilterDrawer} />
          </Grid>
          <Filter openDrawer={filterDrawer} setStockListData={setStockListData} formik={formik} toggleDrawer={handleFilterDrawer} />
        </Grid>
      </Stack>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Product Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Quantity
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Location Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Rack No.
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Updated By
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Created Date
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stockListData?.map((row, index) => {
              var {
                id,
                productName,
                quantity,
                fname,
                locationName,
                lname,
                rankNo,
                createdAt
              } = row;
              return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/stock-details`, { state: { id: id } })
                    localStorage.setItem("stock", JSON.stringify({
                      page: page,
                      search: search,
                      values: formik.values,
                    }));
                  }
                  }
                >
                  <TableCell component="th" scope="row">
                    {productName}
                  </TableCell>
                  <TableCell>{quantity}</TableCell>
                  <TableCell>{locationName}</TableCell>
                  <TableCell>{rankNo}</TableCell>

                  <TableCell>{`${fname} ${lname}`}</TableCell>
                  <TableCell>
                    {moment(createdAt).format("LLL")}
                  </TableCell>
                  <TableCell>
                    <Stack flexDirection={'row'}>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                      >
                        <EditIcon onClick={() => setIsModalOpen({ open: true, currentComponent: "stockAdd", isEdit: true, id: id })} sx={{ width: '20px', height: '20px' }} />
                      </IconButton>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={(e) => {
                          navigate(`/stock-details`, { state: { id: id } })
                          localStorage.setItem("stock", JSON.stringify({
                            page: page,
                            search: search,
                            values: formik.values,
                          }));
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 1024 1024"><path fill="black" d="M512 160c320 0 512 352 512 352S832 864 512 864S0 512 0 512s192-352 512-352m0 64c-225.28 0-384.128 208.064-436.8 288c52.608 79.872 211.456 288 436.8 288c225.28 0 384.128-208.064 436.8-288c-52.608-79.872-211.456-288-436.8-288m0 64a224 224 0 1 1 0 448a224 224 0 0 1 0-448m0 64a160.192 160.192 0 0 0-160 160c0 88.192 71.744 160 160 160s160-71.808 160-160s-71.744-160-160-160" /></svg>
                      </IconButton>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                      >
                        <DeleteIcon onClick={(e) => {
                          e.stopPropagation()
                          handleDeleteSample(id)
                        }
                        } sx={{ width: '20px', height: '20px' }} />
                      </IconButton>
                    </Stack>
                  </TableCell>


                </TableRow>
              );
            })}
            {stockListData === null ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  No records to display
                </TableCell>
              </TableRow>

            ) : (
              stockListData.length === 0 ? (<TableRowsLoader rowsNum={10} />) : ("")
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={page}
        onChange={handleChangePage}
        color="primary"
        sx={{ marginTop: '20px', textAlign: 'center' }}
        data={stockListData}
        totalRecords={totalRecords}
      />

      <Notification show={show} setShow={setShow} />

      <StatusChangeModal
        handleAction={confirmDeleteAdmin}
        handleCloseModal={handleCloseModal}
        setHandelModal={setHandelModal}
        handelModal={handelModal}
      />


      <CommonModal
        modalOpen={isModalOpen}
        setShow={setShow}
        setRefresh={setRefresh}
        data={setStockListData}
        refresh={refresh}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: false })
        } />
    </>
  )
}

export default Stock