import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AddProductMovement from '../../pages/Product-movement/AddProductMovement';
import AddProduct from '../../pages/Product/modal/AddProduct';
import LocationAdd from '../../pages/Location/modal/LocationAdd';
import NewReqAdd from '../../pages/Samplemanagement/modal/NewReq';
import AddMaster from '../../pages/NewMaster/modal/AddMaster';
import MasterToProduct from '../../pages/NewMaster/modal/MasterToProduct';
import StockAdd from '../../pages/StockManagement/modal/StockAdd';
import ChangePassword from './ChangePassword';

export default function CommonModal({ handleClose, modalOpen, setShow, show, data, setIsModalOpen, refresh, setRefresh }) {
    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="Common_modal"
            open={modalOpen.open}
        >
            {modalOpen.currentComponent === 'addProductMovement' && (
                <AddProductMovement handleClose={handleClose} data={data} modalOpen={modalOpen} setShow={setShow} setRefresh={setRefresh} refresh={refresh} />
            )}
            {modalOpen.currentComponent === 'changePassword' && (
                <ChangePassword handleClose={handleClose} modalOpen={modalOpen} setShow={setShow} show={show} />
            )}
            {modalOpen.currentComponent === 'addProduct' && (
                <AddProduct handleClose={handleClose} modalOpen={modalOpen} data={data} setShow={setShow} setRefresh={setRefresh} refresh={refresh} />
            )}
            {modalOpen.currentComponent === 'addMaster' && (
                <AddMaster handleClose={handleClose} modalOpen={modalOpen} data={data} setShow={setShow} setRefresh={setRefresh} refresh={refresh} />
            )}
            {modalOpen.currentComponent === 'addLocation' && (
                <LocationAdd handleClose={handleClose} modalOpen={modalOpen} data={data} setShow={setShow} setRefresh={setRefresh} refresh={refresh} />
            )}
            {modalOpen.currentComponent === 'addNewReq' && (
                <NewReqAdd handleClose={handleClose} data={data} modalOpen={modalOpen} setShow={setShow} setRefresh={setRefresh} refresh={refresh} />
            )}
            {modalOpen.currentComponent === 'masterToProduct' && (
                <MasterToProduct handleClose={handleClose} modalOpen={modalOpen} data={data} setShow={setShow} setRefresh={setRefresh} refresh={refresh} />
            )}
            {modalOpen.currentComponent === 'stockAdd' && (
                <StockAdd handleClose={handleClose} modalOpen={modalOpen} data={data} setShow={setShow} setRefresh={setRefresh} refresh={refresh} />
            )}
        </Dialog>
    );
}
