import React from 'react'
import { CloseIcon } from '../../components/Constants'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import CommonButton from '../../components/common/Button';
import CommonInput from '../../components/common/Input';
import { Stack } from '@mui/system';
import { addUser, updateUser } from '../../Service/user.service';
import { addUserFormInitialValues, updateUserFormInitialValues } from '../../helper/initialValues';
import { useFormik } from "formik";
import { addUserValidationSchema, updateUserValidationSchema } from '../../helper/validation'
import { USER_UPDATE, USER_CREATE, SOMETHING_WENT_WRONG } from '../../components/common/Text'

const AddClient = (props) => {
    const { getUserList, editClientData, handleCloseAddClient, setUserListData, setShow, setEditClientData } = props;

    const formik = useFormik({
        initialValues: addUserFormInitialValues,
        onSubmit: async (values) => {
            try {
                let res;
                if (editClientData && editClientData.id) {
                    const updatePayload = {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        contact: values.contact,
                        email: values.email,
                    };
                    res = await updateUser(updatePayload, editClientData.id);
                } else {
                    const payload = {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        contact: values.contact,
                        email: values.email,
                    };
                    res = await addUser(payload);
                }
                if (res.status === true) {
                    setUserListData([]);
                    handleCloseAddClient();
                    formik.resetForm();
                    getUserList(1, "");
                    setShow({
                        show: true,
                        type: 'success',
                        message: res.message || (editClientData && editClientData.id ? USER_UPDATE : USER_CREATE)
                    });
                } else {
                    setShow({
                        show: true,
                        type: 'error',
                        message: res.message || SOMETHING_WENT_WRONG
                    });
                }
            } catch (error) {
                setShow({
                    show: true,
                    type: 'error',
                    message: error.message || SOMETHING_WENT_WRONG
                });
            }
        },
        validationSchema: editClientData && editClientData.id ? updateUserValidationSchema : addUserValidationSchema,
    });

    const handleModelClose = () => {
        setEditClientData({});
        formik.resetForm();
        handleCloseAddClient();
    }

    React.useEffect(() => {
        if (editClientData && editClientData.id) {
            formik.setFieldValue('firstName', editClientData?.firstName);
            formik.setFieldValue('lastName', editClientData?.lastName);
            formik.setFieldValue('email', editClientData?.email);
            formik.setFieldValue('contact', editClientData?.contact);
        }
    }, [])
    return (
        <>
            <DialogTitle>{editClientData && editClientData.id ? "Edit" : "Add"} Client
                <IconButton
                    aria-label="close"
                    onClick={() => handleModelClose()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={250}>
                            <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                First Name
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                <CommonInput fullWidth placeholder='First Name' name="firstName" formik={formik} maxLength={30} />
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={250} >
                            <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                Last Name
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                <CommonInput fullWidth placeholder='Last Name' name="lastName" formik={formik} maxLength={30} />
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={250}>
                            <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                Contact Number
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                <CommonInput fullWidth placeholder='Contact Number' name="contact" formik={formik} maxLength={15} />
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={250}>
                            <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                                Email
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography className='myprofile_input' sx={{ width: '100%' }}>
                                <CommonInput fullWidth placeholder='Email' name="email" formik={formik} maxLength={250} disabled={editClientData && editClientData.id ? true : false} />
                            </Typography>
                        </Stack>
                    </Grid>

                </Grid>

            </DialogContent>
            <DialogActions>
                <CommonButton onClick={() => handleModelClose()} buttonName="Cancel" />
                <CommonButton onClick={formik.handleSubmit} buttonName={`${props.nameEditBtn || 'Add'}`} />
            </DialogActions>

        </>
    )
}

export default AddClient