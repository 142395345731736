import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { theme, useStyles } from '../../helper/Theme';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  IconButton,

} from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: '#0F0F0F80',
}));

const TopMoveProduct = ({ movementListData }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  console.log('movementListData', movementListData)

  return (
    <>

      <ThemeProvider theme={theme}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader aria-label="simple table" className='small_table'>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                  Product Name
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                  Location
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                  Rack Location
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                  Movement Count
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                  Updated by
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                  Date
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>

                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {movementListData && movementListData.length > 0 && movementListData.map((row, index) => (
                <TableRow
                  key={row.id || index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => navigate(`/product-movement/details`, { state: { id: row.product_id } })}
                >
                  <TableCell component="th" scope="row">
                    {row.productName.length > 20 ? `${row.productName.substring(0, 20)}...` : row.productName}
                  </TableCell>
                  <TableCell>{row.locationName}</TableCell>
                  <TableCell>{row.rackLocation}</TableCell>
                  <TableCell>{row.count}</TableCell>
                  <TableCell>{row.updatedBy}</TableCell>
                  <TableCell>
                    {moment(row.created_at).format('LLL')}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'end' }} onClick={(e) => { e.stopPropagation() }}>
                    <Grid container spacing={2} alignItems="center" flexWrap="nowrap">
                      <Grid item>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={(e) => navigate(`/product-movement/details`, { state: { id: row.product_id } })}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 1024 1024"><path fill="black" d="M512 160c320 0 512 352 512 352S832 864 512 864S0 512 0 512s192-352 512-352m0 64c-225.28 0-384.128 208.064-436.8 288c52.608 79.872 211.456 288 436.8 288c225.28 0 384.128-208.064 436.8-288c-52.608-79.872-211.456-288-436.8-288m0 64a224 224 0 1 1 0 448a224 224 0 0 1 0-448m0 64a160.192 160.192 0 0 0-160 160c0 88.192 71.744 160 160 160s160-71.808 160-160s-71.744-160-160-160" /></svg>
                        </IconButton>
                      </Grid>

                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
              {
                movementListData.length == 0 && (
                  <TableRow>
                    <TableCell
                      sx={{
                        color: 'silver',
                        textAlign: 'center',
                        paddingTop: '90px',
                        borderBottom: 'none',
                        fontSize: '30px',
                      }}
                      colSpan="4"
                    >
                      {movementListData === null ? 'No records to display' : 'Invalid data format'}
                    </TableCell>
                  </TableRow>
                )
              }

              {
                movementListData.length == 0 && (
                  <TableRow>
                    <TableCell
                      sx={{
                        color: 'silver',
                        textAlign: 'center',
                        paddingTop: '90px',
                        borderBottom: 'none',
                        fontSize: '30px',
                      }}
                      colSpan="4"
                    >
                      No records to display
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </>
  );
};

export default TopMoveProduct;
