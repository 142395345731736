import { get, post, postToken, reload, put } from "../web.request";


export const addNewSampleRequest = async (data) => {
  return await post(`/addSample`, data);
}

export const sampleRequestList = async (query) => {
  return await get(`/sampleList${query}`);
}

export const sampleRequestDetail = async (id) => {
  return await get(`/sampleDetail/${id}`);
}

export const updateSampleRequest = async (data, id) => {
  return await put(`/updateSample/${id}`, data);
}

export const statusUpdateAddToShippedSampleRequest = async (id, data) => {
  return await put(`/addToShipped/${id}`, data);
}

export const statusUpdateReadyToShippedSampleRequest = async (id) => {
  return await put(`/readyToShipped/${id}`);
}