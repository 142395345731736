import { get, post, postToken, reload, put, deleteRequest } from "../web.request";


export const stockList = async (query) => {
  return await get(`/stockList${query}`);
}

export const stockAdd = async (payload) => {
  return await post(`/addStock`, payload);
}

export const stockUpdate = async (payload, id) => {
  return await put(`/updateStock/${id}`, payload);
}

export const stockDetails = async (id) => {
  return await get(`/stockDetail/${id}`);
}

//deleteStock
export const deleteStock = async (id) => {
  return await deleteRequest(`/deleteStock/${id}`);
}

export const exportSampleStock = async (query) => {
    return await get(`/exportSampleStock?${query}`);
}

