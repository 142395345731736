import React, { useState, useEffect } from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Stack, } from '@mui/material'
import { CloseIcon } from '../../../components/Constants';
import CommonButton from '../../../components/common/Button';
import CommonAutocomplete from '../../../components/common/Autocomplete';
import CommonInput from '../../../components/common/Input';
import FileUpload from '../../../components/common/FileUpload';
import { SOMETHING_WENT_WRONG, USER_CREATE, USER_UPDATE } from '../../../components/common/Text';
import { addProduct, updateProduct, getProduct } from '../../../Service/product.service';
import { useFormik } from 'formik';
import { productAddFormInitialValues } from '../../../helper/initialValues';
import { addProductValidationSchema } from '../../../helper/validation';
import Notification from '../../../components/common/Notification/Notification';
import { locationDropDown } from '../../../Service/location.service';

const AddProduct = ({ handleClose, modalOpen, data, setShow, setIsModalOpen, refresh, setRefresh }) => {
    const [show1, setShow1] = useState({
        show: false,
        type: '',
        message: ''
    });
    const [files, setFiles] = useState([]);
    const [locationData, setLocationData] = useState([]);
    const [deleteImage, setDeleteImage] = useState([])

    const formik = useFormik({
        initialValues: productAddFormInitialValues,
        onSubmit: async (values) => {
            try {
                let res;
                const formData = new FormData();
                files.map((file) => {
                    if (file.id == undefined) {
                        formData.append('image', file.image);
                    }
                })
                formData.append('name', values.productName);
                formData.append('description', values.productDescription);
                formData.append('location_id', values.location);
                formData.append('random', values.random);
                formData.append('rackLocation', values.rackLocation);
                formData.append('size', values.size);
                formData.append('finish', values.finish);
                formData.append('specifications', "");
                if (modalOpen.isEdit) {

                    formData.append('deletedImageIds', JSON.stringify(deleteImage));
                }

                if (modalOpen && modalOpen.isEdit) {

                    res = await updateProduct(formData, modalOpen.id);
                } else {
                    res = await addProduct(formData);
                }
                if (files.length > 5) {
                    setShow1({
                        show: true,
                        type: 'error',
                        message: "You can only upload up to 5 images" || SOMETHING_WENT_WRONG,
                    });
                } else {
                    if (res.status == true) {
                        data([])
                        formik.resetForm();
                        setShow({
                            show: true,
                            type: 'success',
                            message: res.message || (modalOpen && modalOpen.isEdit ? USER_UPDATE : USER_CREATE),
                        });
                        handleClose()
                        setRefresh(!refresh)
                    } else {
                        setShow1({
                            show: true,
                            type: 'error',
                            message: res.message || SOMETHING_WENT_WRONG,
                        });
                    }
                }
            } catch (error) {
                setShow1({
                    show: true,
                    type: 'error',
                    message: error.message || SOMETHING_WENT_WRONG,
                });
            }
        },
        validationSchema: addProductValidationSchema,
    });

    const getProductData = async () => {
        const res = await getProduct(modalOpen.id);
        if (res.status == true) {
            formik.setFieldValue('productName', res.data.name)
            formik.setFieldValue('productDescription', res.data.description)
            formik.setFieldValue('location', res.data.getLocation?.id)
            formik.setFieldValue('location_id', res.data.getLocation)
            formik.setFieldValue('random', res.data.random)
            formik.setFieldValue('rackLocation', res.data.rackLocation)
            formik.setFieldValue('size', res.data.size)
            formik.setFieldValue('finish', res.data.finish)
            setFiles(res.data && res.data.imageData ? res.data.imageData : [])
        }
    }

    useEffect(() => {
        if (modalOpen?.isEdit) {
            getProductData();
        }
    }, [modalOpen?.isEdit])

    useEffect(() => {
        getLocationData();
    }, [])

    const getLocationData = async () => {
        const res = await locationDropDown();
        if (res.status == true) {
            setLocationData(res.data)
        }
        else {
            setLocationData([])
        }
    }

    return (
        <div>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {modalOpen?.isEdit ? 'Edit' : 'Add'} Master
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Box width={{ sm: '500px' }} >
                    <Stack gap={2}>
                        <Box>
                            <FileUpload setShow1={setShow1} setDeleteImage={setDeleteImage} deleteImage={deleteImage} labelinput='Product Image' files={files} setFiles={setFiles} />
                        </Box>
                        <Box>
                            <CommonInput labelinput='Product Name'
                                formik={formik}
                                name="productName"
                                value={formik.values.productName}
                                maxLength={200}
                            />

                        </Box>
                        <Box>
                            <CommonInput labelinput='Description'
                                formik={formik}
                                name="productDescription"
                                value={formik.values.productDescription}
                                maxLength={200}
                            />
                        </Box>

                        <Box>
                            {modalOpen?.isEdit ? <CommonInput labelinput='Location' formik={formik} name="location" value={formik.values.location_id?.name} disabled /> : (
                                <CommonAutocomplete data={locationData} labelinput='Select Location' formik={formik} name1='location_id' name="location" value={formik.values.location_id}
                                    onChange={(e, val) => {
                                        formik.setFieldValue('location_id', val)
                                        formik.setFieldValue('location', val && val?.id ? val?.id : '')
                                        formik.touched['location_id'] = val && val?.id ? false : true
                                    }}
                                    disabled={modalOpen?.isEdit ? true : false}
                                />)}
                        </Box>

                        <Box>
                            <CommonInput labelinput='Random'
                                formik={formik}
                                name="random"
                                value={formik.values.random}
                                maxLength={10}
                            />
                        </Box>
                        <Box>
                            <CommonInput labelinput='Rack Location'
                                formik={formik}
                                name="rackLocation"
                                value={formik.values.rackLocation}
                                maxLength={10}
                            />
                        </Box>
                        <Box>
                            <CommonInput labelinput='Size'
                                formik={formik}
                                name="size"
                                value={formik.values.size}
                                maxLength={10}
                            />
                        </Box>
                        <Box>
                            <CommonInput labelinput='Finish'
                                formik={formik}
                                name="finish"
                                value={formik.values.finish}
                                maxLength={100}
                            />
                        </Box>
                    </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <CommonButton buttonName='cancel' size='small' color='white' onClick={handleClose} />
                <CommonButton buttonName={modalOpen?.isEdit ? 'Save' : 'Add'} size='small' onClick={formik.handleSubmit} />
            </DialogActions>
            <Notification show={show1} setShow={setShow1} />
        </div>
    )
}

export default AddProduct