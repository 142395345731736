import React, { useEffect, useState } from 'react'
import { Drawer, Grid, Typography, Divider, Stack, IconButton } from '@mui/material';
import { CloseIcon } from '../../helper/Icons'
import CommonInput from '../../components/common/Input';
import CommonButton from '../../components/common/Button';
import CommonSelect from '../../components/common/Select';
import { countryDropDown, stateDropDown, cityDropDown } from '../../Service/location.service'
import CommonAutocomplete from '../../components/common/Autocomplete';

const Filter = ({ openDrawer, toggleDrawer, formik, setProductListData }) => {
  const drawerWidth = 250;
  const [countryList, setCountryList] = useState([])
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])

  useEffect(() => {
    getCountryData()
  }, [])

  const getCountryData = async (id) => {
    const res = await countryDropDown()
    if (res.status === true) {
      setCountryList(res.data)
    }
    else {
      setCountryList([])
    }
  }

  const getStateData = async (id) => {
    const res = await stateDropDown(id)
    if (res.status === true) {
      setStateList(res.data)
    }
    else {
      setStateList([])
    }
  }

  const getCityData = async (id) => {
    const res = await cityDropDown(id)
    if (res.status === true) {
      setCityList(res.data)
    }
    else {
      setCityList([])
    }
  }

  useEffect(() => {
    if (formik.values.country) {
      getStateData(formik.values.country.id)
    }
  }
    , [formik.values.country])

  useEffect(() => {
    if (formik.values.state) {
      getCityData(formik.values.state?.id)
    }
  }
    , [formik.values.state])

  return (
    <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer}
      sx={{
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, padding: 2, justifyContent: 'space-between' },
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography fontWeight={600} fontSize={'20px'}>Filter</Typography>
          <IconButton
            aria-label="close"
            onClick={toggleDrawer}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} marginY={'5px'}>
          <Divider sx={{ borderColor: "border.main", }} />
        </Grid>
        <Grid item xs={12} marginY={2}>
          <Grid container gap={2}>
            <Grid item xs={12}>

              <CommonAutocomplete
                data={countryList}
                labelinput='Select Country'
                onChange={(e, val) => {
                  formik.setFieldValue('country', val)
                  formik.setFieldValue('state', '')
                  formik.setFieldValue('city', '')
                }}
                name='country'
                value={formik.values.country}
              />


            </Grid>
            <Grid item xs={12}>
              <CommonAutocomplete
                data={stateList}
                labelinput='Select State'
                onChange={(e, val) => {
                  formik.setFieldValue('state', val)
                  formik.setFieldValue('city', '')
                }}
                name='state'
                value={formik.values.state}
                formik={formik}
              />


            </Grid>
            <Grid item xs={12}>
              <CommonAutocomplete
                data={cityList}
                labelinput='Select City'
                onChange={(e, val) => {
                  formik.setFieldValue('city', val)
                }}
                name='city'
                value={formik.values.city}
                formik={formik}
              />


            </Grid>
            <Grid item xs={12}>
              <CommonInput
                labelinput='From Date'
                type='date'
                name='startDate'
                value={formik.values.startDate}
                onChange={formik.handleChange}
              />

            </Grid>
            <Grid item xs={12}>
              <CommonInput
                labelinput='To Date'
                min={formik.values.startDate}
                type='date'
                name='endDate'
                disabled={formik.values.startDate ? false : true}
                value={formik.values.endDate}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <CommonSelect
                labelSelect='Select Status'
                selectList={[{ value: '1', label: 'Active' }, { value: '0', label: 'InActive' }, { value: '2', label: 'Delete' }]}
                name='status'
                selectHead='None'
                value={formik.values.status}
                formik={formik}
              />

            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Stack flexDirection={'row'} gap={1}>
            <CommonButton buttonName='Reset' style={{ width: '100%' }} onClick={() => {
              setProductListData([])
              formik.resetForm()
              formik.handleSubmit()
              toggleDrawer()
            }} />
            <CommonButton buttonName='Filter' style={{ width: '100%' }} onClick={() => {
              setProductListData([])
              formik.handleSubmit()
              toggleDrawer()
            }} />
          </Stack>
        </Grid>
      </Grid>
    </Drawer>
  )
}

export default Filter