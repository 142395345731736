import { Card, Grid, } from '@mui/material'
import React from 'react'
import { theme, useStyles } from '../../helper/Theme'
import { Chart } from '../../components/Constants';



const CounterCard = (dashboardData) => {
    const classes = useStyles();

    const CounterData = [
        {
            para: 'Location',
            number: dashboardData?.dashboardData?.locationCount,
        },
        {
            para: 'Users',
            number: dashboardData?.dashboardData?.userCount,
        },
        {
            para: 'Product',
            number: dashboardData?.dashboardData?.productCount,
        },
    ]

    return (
        <Grid container spacing={{ xs: '10px', md: 3 }}>
            {
                CounterData.map((data, i) => {
                    return (
                        <Grid item theme={theme} xs={6} sm={6} md={4} key={i}>
                            <Card className={classes.cardContainer}>
                                <Grid container padding={{ xs: 2, md: 3 }} sx={{ background: 'var(--cardBG)' }}>
                                    <Grid item xs={8} className={classes.smallHead}>{data.para}</Grid>
                                    <Grid item xs={8}>
                                        <Grid container spacing={'3px'}>
                                            <Grid item xs={12} className={classes.counterNumber}>{data.number}</Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    );
                })
            }
        </Grid>
    )
}

export default CounterCard