import React, { useEffect, useState } from 'react'
import { useStyles } from '../../helper/Theme';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import PageHeading from '../../components/common/PageHeading';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { getProductDetails } from '../../Service/product.service';
import { useLocation } from 'react-router-dom'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import CommonButton from '../../components/common/Button';
import ImageViewer from 'react-simple-image-viewer';


const MovementDetails = () => {
    const classes = useStyles();
    const [productData, setProductData] = useState([])
    const [refresh, setRefresh] = useState(false);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const closeImageViewer = () => {
        setIsViewerOpen(false);
    };

    const [imageData, setImageData] = useState([])
    const { state } = useLocation()
    const Navigate = useNavigate()


    const getProductData = async (id) => {
        const res = await getProductDetails(id);
        if (res.status == true) {
            setProductData(res.data)
        }
    }

    useEffect(() => {
        if (state && state.id) {
            getProductData(state?.id)
        }
    }, [state])


    return (
        <>
            <PageHeading heading='Master Movement Details' />
            <CommonButton onClick={() => Navigate(-1)} variant="contained" color="primary" buttonName="Back" />
            <div style={{
                marginTop: '20px',
            }}> </div>
            <Box className={classes.cardContainer} sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
                <Stack flexDirection={'row'} gap={3} alignItems={'center'}>
                    <Stack gap={2}>
                        <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
                            <Typography fontSize={{ xs: '16px', sm: '20px' }} >Product Name :</Typography>
                            <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{productData?.name}</Typography>
                        </Stack>
                        <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
                            <Typography fontSize={{ xs: '16px', sm: '20px', whiteSpace: 'nowrap' }} >Description :</Typography>
                            <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{productData?.description}</Typography>
                        </Stack>
                        <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
                            <Typography fontSize={{ xs: '16px', sm: '20px' }}>Random :</Typography>
                            <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{productData?.random} </Typography>
                        </Stack>
                        <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
                            <Typography fontSize={{ xs: '16px', sm: '20px' }}>Finish :</Typography>
                            <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{productData?.finish} </Typography>
                        </Stack>
                        <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 0, sm: 2 }}>
                            <Typography fontSize={{ xs: '16px', sm: '20px' }} >Size :</Typography>
                            <Typography fontSize={{ xs: '16px', sm: '20px' }} fontWeight={'600'}>{productData?.size} </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Grid container spacing={1} mt={2}>
                    {
                        productData && productData?.imageData && productData?.imageData.length > 0 && productData?.imageData?.map((data, i) => {
                            return (
                                <Grid item xs={6} sm={4} md={3} lg={2} key={i}
                                    onClick={() => {
                                        setImageData(data)
                                        setIsViewerOpen(true)
                                    }}
                                >
                                    <img src={data?.image} alt='' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                </Grid>
                            )
                        })
                    }
                </Grid>

                <Stack mt={3} >
                    <Typography fontSize={'20px'} fontWeight={'600'}>Product Movement Timeline</Typography>
                    <Timeline lineColor={'#ddd'}>
                        {productData && productData?.trackData?.map((data, i) => {
                            var name = data?.updatedBy && data?.updatedBy[0] ? data?.updatedBy[0].firstName + ' ' + data?.updatedBy[0].lastName : ''
                            var location = data?.location_id && data?.location_id[0] ? `${data?.location_id[0].name}, ${data?.location_id[0].city_id}` : ''
                            return (
                                <TimelineItem
                                    key={i}
                                    dateText={moment(data?.move_time).format('MM-DD-YYYY hh:mm A')}
                                    style={{ color: '#e54e09' }}
                                >
                                    <Box sx={{ background: '#E54E0920', p: '10px 16px', maxWidth: '600px' }} className='timeline_box'>
                                        {/* <Typography fontSize={'18px'} fontWeight={'600'}>{name}</Typography> */}
                                        <Typography fontSize={'12px'} ><span style={{ fontWeight: '600', color: '#000' }}>Last Update by :</span> {name}</Typography>
                                        <Typography fontSize={'12px'} ><span style={{ fontWeight: '600', color: '#000' }}>Location :</span> {location}</Typography>
                                        <Typography fontSize={'12px'} ><span style={{ fontWeight: '600', color: '#000' }}>Note :</span> {data?.note}</Typography>
                                        <Typography fontSize={'12px'} ><span style={{ fontWeight: '600', color: '#000' }}>Vehicle No :</span> {data?.vehicleNumber || '   -'}</Typography>
                                    </Box>
                                </TimelineItem>
                            )
                        })
                        }
                    </Timeline>
                </Stack>
            </Box >

            {isViewerOpen && (<>
                < ImageViewer
                    src={[imageData?.image]}
                    currentIndex={0}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            </>
            )}
        </>
    )
}

export default MovementDetails