import React, { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import Heading from '../../components/Heading';
import NewRequest from './sections/NewRequest';
import ShippedPage from './sections/Shipped';
import { useLocation } from 'react-router-dom'

const SampleManagement = () => {
  const [newrequest, setNewRequest] = useState(true);
  const [shipped, setShipped] = useState(false);
  const { state } = useLocation();

  const handleAdminClick = () => {
    setNewRequest(true)
    setShipped(false)
  }
  const handleRolesClick = () => {
    setNewRequest(false)
    setShipped(true)
  }

  useEffect(() => {
    const data = localStorage.getItem('tab')

    if (data) {
      const parsedData = JSON.parse(data)
      if (parsedData.tab == "shipped") {
        handleRolesClick()
      } else {
        handleAdminClick()
      }
      localStorage.removeItem('tab')
    }
  }
    , [])

  useEffect(() => {
    if (state && state.isRole === true) {
      handleRolesClick()
    }
  }, [state])

  return (
    <>
      <Stack flexDirection={'row'} gap={1} alignItems={'center'} mb={1}>
        <Heading head='Sample Request' />
      </Stack>
      <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'} gap={{ xs: 1, sm: 1 }} mb={2}>
        <Stack className='custome_tab_menu'>
          <Box onClick={handleAdminClick} sx={{ color: `${!shipped ? '#fff' : ''}`, background: `${!shipped ? '#FF7733' : ''}` }}>New Request</Box>
          <Box onClick={handleRolesClick} sx={{ color: `${!newrequest ? '#fff' : ''}`, background: `${!newrequest ? '#FF7733' : ''}` }}>Shipped</Box>
        </Stack>
      </Stack>
      {newrequest && <NewRequest />}
      {shipped && <ShippedPage />}
    </>
  )
}

export default SampleManagement