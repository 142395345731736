import { Box, Stack } from '@mui/material'
import React from 'react'
import Pagination from '@mui/material/Pagination';

const Paginations = ({ onChange, count, page, data, totalRecords }) => {

  return (
    <Box>
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          {data?.length || 0}{" "}

          of {totalRecords} Results
        </div>
        <Pagination
          variant="outlined"
          shape="rounded"
          count={count}
          page={page}
          onChange={onChange}
        />
      </Stack>

    </Box>
  )
}

export default Paginations