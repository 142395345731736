import React, { useEffect, useState } from 'react'
import { Drawer, Grid, Typography, Divider, Stack, IconButton, debounce } from '@mui/material';
import { CloseIcon } from '../../helper/Icons'
import CommonInput from '../../components/common/Input';
import CommonButton from '../../components/common/Button';
import CommonAutocomplete from '../../components/common/Autocomplete';
import { productDropDown } from '../../Service/product.service';
import { locationDropDown } from '../../Service/location.service';
import CommonAutocomplete1 from '../../components/common/Autocomplete1';

const AddFilter = ({ openDrawer, toggleDrawer, formik, setMovementListData }) => {
    const drawerWidth = 250;
    const [productData, setProductData] = useState([]);
    const [locationData, setLocationData] = useState([]);

    useEffect(() => {
        productDropDownData();
        getLocationData();
    }, [])

    const productDropDownData = async () => {
        const res = await productDropDown();
        if (res.status == true) {
            setProductData(res.data)
        } else {
            setProductData([])
        }
    }


    const getLocationData = async () => {
        const res = await locationDropDown();
        if (res.status == true) {
            setLocationData(res.data)
        }
        else {
            setLocationData([])
        }
    }

    const debouncedOnChange = debounce((e, val) => {
        formik.setFieldValue('product_id', val ? val : '')
        formik.setFieldValue('product', val && val?.id ? val?.id : '')
        formik.touched['product_id'] = val && val?.id ? false : true
    }, 300);

    return (
        <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer}
            sx={{
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, padding: 2, justifyContent: 'space-between' },
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Typography fontWeight={600} fontSize={'20px'}>Filter</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={toggleDrawer}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={12} marginY={'5px'}>
                    <Divider sx={{ borderColor: "border.main", }} />
                </Grid>
                <Grid item xs={12} marginY={2}>
                    <Grid container gap={2}>
                        <Grid item xs={12}>
                            <CommonAutocomplete1 data={productData} formik={formik} labelinput='Select Product' name1='product_id' name="product" value={formik.values.product_id}
                                onChange={(e, val) => {

                                    debouncedOnChange(e, val)

                                }} />
                        </Grid>
                        <Grid item xs={12}>
                            <CommonAutocomplete data={locationData} labelinput='Select Location' formik={formik} name1='location_id' name="location" value={formik.values.location_id}
                                onChange={(e, val) => {
                                    formik.setFieldValue('location_id', val)
                                    formik.setFieldValue('location', val && val?.id ? val?.id : '')
                                    formik.touched['location_id'] = val && val?.id ? false : true
                                }} />
                        </Grid>
                        <Grid item xs={12}>
                            <CommonInput
                                labelinput='From Date'
                                type='date'
                                name='startDate'
                                value={formik.values.startDate}
                                onChange={formik.handleChange}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <CommonInput
                                labelinput='To Date'
                                type='date'
                                name='endDate'
                                min={formik.values.startDate}
                                value={formik.values.endDate}
                                disabled={formik.values.startDate ? false : true}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Stack flexDirection={'row'} gap={1}>
                        <CommonButton buttonName='Reset' style={{ width: '100%' }} onClick={() => {
                            formik.resetForm()
                            formik.handleSubmit()
                            toggleDrawer()
                            setMovementListData([])
                        }} />
                        <CommonButton buttonName='Filter' style={{ width: '100%' }} onClick={() => {
                            formik.handleSubmit()
                            setMovementListData([])
                            toggleDrawer()
                        }} />
                    </Stack>
                </Grid>
            </Grid>
        </Drawer>
    )
}

export default AddFilter