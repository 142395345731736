import React from 'react';
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import CommonButton from '../Button';

const StatusChangeModal = ({ handleCloseModal, handleAction, handelModal }) => (
  <>
    <Dialog open={handelModal.open} onClose={handleCloseModal} sx={{ '& .MuiDialog-paper': { width: '500px' } }}>
      <DialogContent dividers>
        <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
          {handelModal?.message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <CommonButton onClick={handleCloseModal} buttonName="No" />
        <CommonButton onClick={handleAction} buttonName="Yes" />
      </DialogActions>
    </Dialog>
  </>
);

export default StatusChangeModal;
