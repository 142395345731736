import { get, post, postToken, reload, put, deleteRequest } from "../web.request";


export const LocationList = async (query) => {
    return await get(`/locationList${query}`);
}
export const deleteLocation = async (id) => {
    return await deleteRequest(`/deleteLocation/${id}`);
}
export const addLocation = async (payload) => {
    return await post(`/addLocation`, payload);
}
export const updateLocation = async (payload, id) => {
    return await put(`/updateLocation/${id}`, payload);
}
export const getLocation = async (id) => {
    return await get(`/locationDetail/${id}`);
}
export const locationStatus = async (id) => {
    return await put(`/statusChangeLocation/${id}`);
}

export const countryDropDown = async () => {
    return await get(`countryDropdown`);
}
export const stateDropDown = async (id) => {
    return await get(`stateDropdown/${id}`);
}
export const cityDropDown = async (id) => {
    return await get(`cityDropdown/${id}`);
}

export const locationDropDown = async () => {
    return await get(`locationDropdown`);
}
