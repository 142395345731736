import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from '../action-types/authActionType';


const initialState = {
    user: {},
    isAuthenticate: false,
    token: '',
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload || {},
                isAuthenticate: true,
                token: action.payload?.token || ''
            };
        case LOGOUT:
            return {
                ...state,
                user: {},
                isAuthenticate: false,
                token: ''
            };
        default:
            return state;
    }
}
