import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import PageHeading from '../../components/common/PageHeading';
import { theme, useStyles } from '../../helper/Theme';
import {
  Grid, Stack, TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from 'react-router-dom';
import { MovementAdd, MovementList, exportTracking } from '../../Service/Movement.service';
import moment from "moment";
import Notification from '../../components/common/Notification/Notification';
import { EditIcon, AddIcon } from '../../components/Constants';
import CommonButton from '../../components/common/Button';
import Skeleton from '@mui/material/Skeleton';
import TableSearch from '../../components/common/Table/TableSearch';
import { FiFilter } from '../../helper/Icons';
import CommonModal from '../../components/common/Modal';
import Pagination from "../../components/common/Table/Pagination";
import AddFilter from './AddFilter';
import { addProductTrackFormInitialValues, moveProductInitialValues } from '../../helper/initialValues';
import { useFormik } from 'formik';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CommonAutocomplete from '../../components/common/Autocomplete';
import CommonInput from '../../components/common/Input';
import { locationDropDown } from '../../Service/location.service';
import { moveProductValidationSchema } from '../../helper/validation';
import { SOMETHING_WENT_WRONG, USER_CREATE } from '../../components/common/Text';
import StatusChangeModal from '../../components/common/modal/DeleteModal';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const Movement = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [movementListData, setMovementListData] = useState([]);
  const [page, setPage] = useState(localStorage.getItem("productData") ? JSON.parse(localStorage.getItem("productData")).page : 1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [sortBy, setSortBy] = useState(false);
  const [shortKey, setShortKey] = useState('created_at');
  const [sortCount, setSortCount] = useState(false)
  const [sortDate, setSortDate] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: false
  });
  const [handelModal, setHandelModal] = useState({
    open: false,
    type: '',
    message: ''
  });

  const formik = useFormik({
    initialValues: addProductTrackFormInitialValues,
    onSubmit: async (values) => {
      setRefresh(!refresh)

    },
  });

  const handleCloseModal = () => {
    setHandelModal({
      open: false,
      type: '',
      message: '',
    })
  }


  const handleSortClick = (name, by) => {
    switch (name) {
      case 'created_at':
        setSortBy(!by)
        setSortDate((current) => !current)
        setSortCount(false)
        setShortKey('created_at')
        break;
      case 'count':
        setSortBy(!by)
        setSortCount((current) => !current)
        setSortDate(false)
        setShortKey('count')
        break;

      default:
        setSortBy(!sortDate)
        setSortDate(false)
        setShortKey('created_at')
        setSortCount((current) => !current)
        break;
    }
  };


  const formik1 = useFormik({
    initialValues: moveProductInitialValues,
    onSubmit: async (values) => {
      const payload = {
        "product_id": values.product_id,
        "location_id": values.location,
        "note": values.note,
        "vehicleNumber": values.vehicleNumber,
        "rackLocation": values.rackLocation
      };
      const res = await MovementAdd(payload);

      if (res.status == true) {
        formik.resetForm();
        setShow({
          show: true,
          type: 'success',
          message: res.message || USER_CREATE,
        });
        setMovementListData([]);
        setOpen(0);
        setRefresh(!refresh)
      } else {
        setShow({
          show: true,
          type: 'error',
          message: res.message || SOMETHING_WENT_WRONG,
        });
      }




    },
    validationSchema: moveProductValidationSchema
  });



  useEffect(() => {
    getLocationData();
  }, [])

  const getLocationData = async () => {
    const res = await locationDropDown();
    if (res.status == true) {
      setLocationData(res.data)
    }
    else {
      setLocationData([])
    }
  }

  const [show, setShow] = useState({
    show: false,
    type: '',
    message: ''
  });
  const [filterDrawer, setFilterDrawer] = React.useState(false);
  const handleFilterDrawer = () => {
    setFilterDrawer(!filterDrawer);
  };



  const handleChangePage = (event, value) => {
    setMovementListData([]);
    setPage(value);
  };


  const handelExport = async (search) => {
    let query = `search=${search}&product_id=${formik.values.product}&location_id=${formik.values.location}&startDate=${formik.values.startDate}&endDate=${formik.values.endDate}&sortFlag=${sortBy}&orderBy=${shortKey}`;
    const res = await exportTracking(query);

    const url = window.URL.createObjectURL(new Blob([res.data]));

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'product-movement.csv');
    document.body.appendChild(link);
    link.click();
    setShow({
      show: true,
      type: 'success',
      message: res.message || 'Exported successfully'
    })
    setHandelModal({
      open: false,
      type: '',
      message: ''
    })

  }

  const getMovementList = async (page, search) => {
    let query = `page=${page}&limit=${pagePerSize}&search=${search}&product_id=${formik.values.product}&location_id=${formik.values.location}&startDate=${formik.values.startDate}&endDate=${formik.values.endDate}&sortFlag=${sortBy}&orderBy=${shortKey}`;

    const res = await MovementList(query);
    if (res.status) {
      setMovementListData(res?.data)
      setTotalRecords(res?.total || 0);
      setTotalPages(Math.ceil(res?.total / pagePerSize));

    } else {
      setMovementListData(null)
      setTotalRecords(0);
      setTotalPages(0);
    }
  }

  const getSearchKeywords = (e) => {
    setPage(1);
    setSearch(e.target.value);
  }


  const TableRowsLoader = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
      </TableRow>
    ));
  };

  const confirmDeleteAdmin = async () => {
    handelExport(search);
  }

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("productData"));

    if (data) {
      setTotalRecords(0);
      setPage(data.page);
      if (data.search) {
        setSearch(data.search);
      }
      formik.setValues(data.values);
      formik.handleSubmit();
      setSortBy(data.sortBy);
      setShortKey(data.shortKey);
      localStorage.removeItem("productData");
    } else {
      getMovementList(page, search);
    }
  }, [refresh, search, page, sortBy, shortKey]);

  return (
    <>
      {location.pathname === '/' ? '' : <PageHeading heading='Master Movement' />}
      {location.pathname === '/' ? '' :

        <Stack alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: '8px' }}>
          <TableSearch value={search} onChange={getSearchKeywords} />
          <Grid container justifyContent='flex-end' alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit">
            <Grid item>
              <CommonButton className='export_button' buttonName={'Export'}
                onClick={() => {
                  setHandelModal({ open: true, type: 'status', message: 'Are you sure you want to export?' })
                }} />
            </Grid>

            <Grid item>
              <CommonButton buttonName={<FiFilter style={{ fontSize: '22px' }} />} onClick={handleFilterDrawer} />
            </Grid>
            <AddFilter setMovementListData={setMovementListData} formik={formik} openDrawer={filterDrawer} toggleDrawer={handleFilterDrawer} />
          </Grid>
        </Stack>
      }


      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Product Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Location
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                updated by
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Rack Location
              </StyledTableCell>
              <StyledTableCell sx={{ cursor: 'pointer', fontWeight: '700', color: '#000' }}
                onClick={() => handleSortClick('count', sortCount)}>
                Movement Count &nbsp;
                {sortCount ?
                  <FaArrowUp style={{ color: '#adadad' }} />
                  :
                  <FaArrowDown style={{ color: '#adadad' }} />
                }
              </StyledTableCell>
              <StyledTableCell sx={{ cursor: 'pointer', fontWeight: '700', color: '#000' }} onClick={() => handleSortClick('created_at', sortDate)}>
                date &nbsp;
                {sortDate ?
                  <FaArrowUp style={{ color: '#adadad' }} />
                  :
                  <FaArrowDown style={{ color: '#adadad' }} />
                }
              </StyledTableCell>
              {location.pathname === '/' ? '' : <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}></StyledTableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {movementListData?.map((row, index) => {
              var {
                id,
                firstName,
                lastName,
                productName,
                locationName,
                created_at,
                updatedBy,
                trackData,
                rackLocation,
                count,
                product_id
              } = row;
              return (
                <>
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => {
                      setOpen(open === id ? 0 : id);
                      formik1.resetForm();
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell component="th" scope="row">
                      {productName.length > 20 ? `${productName.substring(0, 20)}...` : productName}
                    </TableCell>
                    <TableCell>{locationName}</TableCell>
                    <TableCell>{updatedBy}</TableCell>
                    <TableCell>{rackLocation}</TableCell>
                    <TableCell>{count}</TableCell>
                    <TableCell onClick={(e) => { e.stopPropagation(); }}>
                      {moment(row.created_at).format("LLL")}
                    </TableCell>
                    {/* { */}
                    <TableCell sx={{ textAlign: 'end' }} onClick={(e) => { e.stopPropagation() }}>
                      <Grid container spacing={2} alignItems="center" flexWrap="nowrap">
                        <Grid item>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={(e) => {
                              navigate(`/product-movement/details`, {
                                state: {
                                  id: product_id,
                                }
                              })
                              localStorage.setItem("productData", JSON.stringify({
                                page: page,
                                search: search,
                                values: formik.values,
                                sortBy: sortBy,
                                shortKey: shortKey
                              }));
                            }}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 1024 1024"><path fill="black" d="M512 160c320 0 512 352 512 352S832 864 512 864S0 512 0 512s192-352 512-352m0 64c-225.28 0-384.128 208.064-436.8 288c52.608 79.872 211.456 288 436.8 288c225.28 0 384.128-208.064 436.8-288c-52.608-79.872-211.456-288-436.8-288m0 64a224 224 0 1 1 0 448a224 224 0 0 1 0-448m0 64a160.192 160.192 0 0 0-160 160c0 88.192 71.744 160 160 160s160-71.808 160-160s-71.744-160-160-160" /></svg>
                          </IconButton>
                        </Grid>

                      </Grid>
                    </TableCell>
                    {/* } */}
                  </TableRow >
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} className='collapse_row'>
                      <Collapse in={open === id}
                        timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 2 }}>
                          <Grid container gap={{ xs: 2 }} alignItems={'flex-start'} justifyContent={{ sm: 'center' }}>
                            <Grid item xs={10.2}>
                              <Typography fontWeight={'700'} fontSize={'20px'} mb={2}>
                                Move Master
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={5}>
                              <CommonAutocomplete data={locationData} labelinput='Select Location' formik={formik1} name1='location_id' name="location" value={formik1.values.location_id}
                                onChange={(e, val) => {
                                  formik1.setFieldValue('location_id', val)
                                  formik1.setFieldValue('location', val && val?.id ? val?.id : '')
                                  formik1.setFieldValue('product_id', product_id)
                                  formik1.touched['location_id'] = val && val?.id ? false : true
                                }}
                              />
                            </Grid>
                            <Grid item xs={6} sm={5}>
                              <CommonInput labelinput='Note' formik={formik1} name="note" value={formik1.values.note} maxLength={100} />
                            </Grid>
                            <Grid item xs={6} sm={5}>
                              <CommonInput labelinput='Vehicle No' formik={formik1} name="vehicleNumber" value={formik1.values.vehicleNumber} maxLength={20} />
                            </Grid>
                            <Grid item xs={6} sm={5}>
                              <CommonInput labelinput='Rack Location' formik={formik1} name="rackLocation" value={formik1.values.rackLocation} maxLength={10} />
                            </Grid>
                            <Grid item xs={10.2}>
                              <CommonButton buttonName={'move'} className='move_product' onClick={formik1.handleSubmit} />
                            </Grid>
                          </Grid>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
            {
              movementListData === null ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    No records to display
                  </TableCell>
                </TableRow>

              ) : (
                movementListData.length === 0 ? (<TableRowsLoader rowsNum={10} />) : ("")
              )
            }
          </TableBody >
        </Table >
      </TableContainer >
      {
        location.pathname === '/' ? '' :
          <>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              color="primary"
              sx={{ marginTop: '20px', textAlign: 'center' }}
              data={movementListData}
              totalRecords={totalRecords}
            />
            <StatusChangeModal
              handleAction={confirmDeleteAdmin}
              handleCloseModal={handleCloseModal}
              setHandelModal={setHandelModal}
              handelModal={handelModal}
            />

            <CommonModal
              modalOpen={isModalOpen}
              setShow={setShow}
              setRefresh={setRefresh}
              refresh={refresh}
              data={setMovementListData}
              handleClose={() =>
                setIsModalOpen({ open: false, currentComponent: "", isEdit: false })
              } />
          </>
      }


    </>
  )
}

export default Movement