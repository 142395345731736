import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

function Notification({ show, setShow }) {
    const { type, message } = show;

    const notificationStyle = {
        position: 'fixed',
        top: '10px',
        right: '10px',
        zIndex: 9999,
    };

    const closeNotification = () => {
        setShow({
            show: false,
            type: '',
            message: '',
        });
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            closeNotification();
        }, 5000);

        return () => clearTimeout(timer);
    }, [show]);


    return (
        <>
            {show && show.show ? (
                <div style={notificationStyle}>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert onClose={() => {
                            closeNotification();
                        }} severity={type}>
                            {message}
                        </Alert>
                    </Stack>
                </div>
            ) : null}
        </>
    );
}

export default Notification;
