import React, { useEffect, useState } from 'react'
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import {
  MainLogo,
  Vector1, Vector2, Vector3, Vector4, Vector5, Vector7, Vector8, Vector10,
  Access, Ads, Calc, Client, Dashboard, Leads, Keyword, Reporting,
} from '../Constants'
import List from '@mui/material/List';
import { Box, ListItemText } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { IsRead } from '../../helper/handlePermission'
import { useSelector } from 'react-redux';
import { getUnreadNotification } from '../../Service/notification.service';

const SidebarList = [

  {
    name: 'Dashboard',
    icon: Vector10,
    activeIcon: Dashboard,
    path: '/'
  },
  {
    name: 'New Master Request',
    icon: Vector4,
    activeIcon: Keyword,
    path: '/new-master-request'
  },
  {
    name: 'Master Stock',
    icon: Vector1,
    activeIcon: Ads,
    path: '/master'
  },
  {
    name: 'Master Movement',
    icon: Vector4,
    activeIcon: Keyword,
    path: '/product-movement'
  },

  {
    name: 'Sample Request',
    icon: Vector4,
    activeIcon: Keyword,
    path: '/sample-management'
  },
  {
    name: 'Sample Stock',
    icon: Vector4,
    activeIcon: Keyword,
    path: '/stock-management'
  },
  {
    name: 'Location',
    icon: Vector5,
    activeIcon: Calc,
    path: '/location'
  },
  {
    name: 'Users',
    icon: Vector2,
    activeIcon: Leads,
    path: '/user'
  },
  {
    name: "Notification",
    icon: Vector7,
    activeIcon: Reporting,
    path: '/notification'
  }
]

const Sidebar = ({ handleDrawerToggle, drawerWidth }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state?.root?.auth);

  const [count, setCount] = useState(0);
  const getCount = async () => {
    const res = await getUnreadNotification();
    if (res.status) {
      // return res.data;
      setCount(res.data)
    }
  }

  useEffect(() => {
    getCount();
  }, [])

  return (
    <Box width={drawerWidth}>
      <List sx={{
        padding: '24px 24px 15px 24px',
      }}>
        <img src="https://asia-pacific-ceramic.s3.ap-south-1.amazonaws.com/images/main_logo.png" alt='main_logo' style={{
          width: '130px',

        }} />
      </List>
      <List>
        {SidebarList
          .map((data, index) => (
            user && user.role === 2 && data.name === 'Users' ? null :
              <ListItem
                onClick={() => {
                  getCount();
                  navigate(data.path);
                }}
                key={data.name} // Use a unique key
                disablePadding
                sx={{ marginBottom: '8px' }}
              >
                <ListItemButton
                  onClick={handleDrawerToggle}
                  key={index}
                  sx={{
                    backgroundColor: location.pathname === data.path ? '#FEF9F8' : '',
                    borderLeft: location.pathname === data.path ? '4px solid var(--primary) !important' : '4px solid transparent !important',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 30,
                      width: '13px',
                      height: '17px',
                    }}
                  >
                    <img src={location.pathname === data.path ? data.activeIcon : data.icon} alt='' />
                  </ListItemIcon>
                  <ListItemText
                    primary={data.name}
                    sx={{
                      color: location.pathname === data.path ? 'var(--primary)' : '',
                      fontSize: '15px',
                    }}
                  />
                  {data && data?.name === 'Notification' &&
                    <div style={{ background: '#E54E09', borderRadius: '50%', width: '25px', height: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <p style={{ lineHeight: '1', color: '#fff' }}>{count}</p>
                    </div>
                  }
                </ListItemButton>
              </ListItem>
          ))
        }
      </List>
    </Box>
  )
}

export default Sidebar