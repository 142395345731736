import { get, post, postToken, reload, put, deleteRequest } from "../web.request";


export const MasterList = async (query) => {
  return await get(`/masterReqList?${query}`);
}
export const masterDropDown = async () => {
  return await get(`/productDropdown`);
}

export const addMaster = async (payload) => {
  return await post(`/addMasterReq`, payload);
}
export const updateMaster = async (payload, id) => {
  return await put(`/updateMasterReq/${id}`, payload);
}
export const deleteMaster = async (id) => {
  return await deleteRequest(`/deleteRequest/${id}`);
}
export const getMasterDetails = async (id) => {
  return await get(`/masterReqDetail/${id}`);
}
export const masterStatus = async (id) => {
  return await put(`/statusChangeRequest/${id}`);
}

export const convertToProduct = async (id, data) => {
  return await put(`/convertToProduct/${id}`, data);
}


