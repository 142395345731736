import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import PageHeading from '../../components/common/PageHeading';
import { theme, useStyles } from '../../helper/Theme';
import {
	Grid, Stack, TableContainer,
	Paper,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Chip,
	Switch
} from '@mui/material';
import { styled } from "@mui/material/styles";
import AddClient from './AddClient';
import { useNavigate } from 'react-router-dom';
import { userList, deleteUser, statusChange, updateUser } from '../../Service/user.service';
import moment from "moment";
import ChangePassword from '../../components/common/modal/ChangePassword';
import { Dialog, DialogTitle } from '@mui/material';
import Notification from '../../components/common/Notification/Notification';
import { MoreVertIcon, EditIcon, AddIcon, DeleteIcon, LockIcon } from '../../components/Constants';
import { Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CommonButton from '../../components/common/Button';
import Skeleton from '@mui/material/Skeleton';
import StatusChangeModal from '../../components/common/modal/DeleteModal';
import { DELETE_MESSAGE, SOMETHING_WENT_WRONG, STATUS_MESSAGE } from '../../components/common/Text';
import Paginations from '../../components/common/Table/Pagination';
import TableSearch from '../../components/common/Table/TableSearch';
import AddFilter from './AddFilter';
import { FiFilter } from '../../helper/Icons';
import { useFormik } from 'formik';
import { addFilterInitialValues } from '../../helper/initialValues'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	color: "#0F0F0F80",
}));

const Clientmanagement = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [userListData, setUserListData] = useState([]);
	const [page, setPage] = useState(1);
	const [pagePerSize, setPagePerSize] = useState(10);
	const [totalRecords, setTotalRecords] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const [changePassId, setChangePassId] = useState(null);
	const [openAdd, setOpenAdd] = React.useState(false);
	const [addEditClientPopup, setAddEditClientPopup] = React.useState(false);
	const [editClientData, setEditClientData] = React.useState({});
	const [editId, setEditId] = React.useState({})
	const [search, setSearch] = useState("");
	const [handelModal, setHandelModal] = useState({
		open: false,
		type: '',
		message: '',
		id: ""
	});
	const [filterDrawer, setFilterDrawer] = React.useState(false);
	const handleFilterDrawer = () => {
		setFilterDrawer(!filterDrawer);
	};


	const [show, setShow] = useState({
		show: false,
		type: '',
		message: ''
	});

	const formik = useFormik({
		initialValues: addFilterInitialValues,
		onSubmit: async (values) => {
			getUserList(page, search);

		}
	});


	const handleOpen = () => {
		setOpenAdd(true);
	};
	const handleClose = () => {
		setOpenAdd(false);
	};
	const handleOpenAddClient = () => {
		setAddEditClientPopup(true);
	};
	const handleCloseAddClient = () => {
		setAddEditClientPopup(false);
	};

	const handleChangePage = (event, value) => {
		setUserListData([])
		setPage(value);
		getUserList(value, search);
	};

	const handlePasswordChange = async (data) => {
		if (data?.id) {
			handleOpen();
			setChangePassId(data.id)
		}
	}
	const handleCloseModal = () => {
		setHandelModal({
			open: false,
			type: '',
			message: '',
			id: ""
		})
	}

	const confirmDeleteAdmin = async () => {
		if (handelModal.id) {
			let res = "";
			if (handelModal.type == "status") {
				res = await statusChange(handelModal.id);
			} else {
				res = await deleteUser(handelModal.id);
			}

			if (res.status == true) {
				setUserListData([])
				handleChangePage(null, page)
				setShow({
					show: true,
					type: 'success',
					message: res.message || handelModal.type == "status" ? STATUS_MESSAGE : DELETE_MESSAGE
				})
				setHandelModal({
					open: false,
					type: '',
					message: '',
					id: ""
				})
			} else {
				setShow({
					show: true,
					type: 'error',
					message: res.message || 'Something went wrong'
				})
			}
		}
	}

	const handleDeleteAdmin = (id) => {
		if (id) {
			setHandelModal({
				open: true,
				type: 'delete',
				message: 'Are you sure you want to delete this client?',
				id: id
			})
		}
	}

	const handleEditClient = async (row) => {
		if (row) {
			setEditClientData(row);
			handleOpenAddClient();
		} else {
			setShow({
				show: true,
				type: 'error',
				message: 'Something goes wrong'
			})
		}
	}

	const getUserList = async (page, search) => {
		let query = `?page=${page}&limit=${pagePerSize}&search=${search}&status=${formik.values.status}`;

		const res = await userList(query);
		if (res.status) {
			setTimeout(() => {
				setUserListData(res?.data)
				setTotalRecords(res?.total);
				setTotalPages(Math.ceil(res?.total / pagePerSize));
			}, 1000);

		} else {
			setUserListData(null)
			setTotalRecords(0);
			setTotalPages(0);
		}
	}

	const getSearchKeywords = (e) => {
		setSearch(e.target.value);
		if (e.target.value.length > 3) {
			getUserList(1, e.target.value);
		} else if (e.target.value.length == 0) {
			getUserList(1, "");
		}
	}

	const TableRowsLoader = ({ rowsNum }) => {
		return [...Array(rowsNum)].map((row, index) => (
			<TableRow key={index}>
				<TableCell component="th" scope="row">
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
				<TableCell>
					<Skeleton animation="wave" variant="text" width={210} height={20} />
				</TableCell>
			</TableRow>
		));
	};

	useEffect(() => {
		getUserList(1, "");
	}, [])

	return (
		<>
			<PageHeading heading='User Management' />
			<Stack alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: '8px' }}>
				<TableSearch value={search} onChange={getSearchKeywords} />
				<Grid container justifyContent='flex-end' alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit">
					<Grid item>
						<CommonButton buttonName={<AddIcon sx={{ fontSize: '22px' }} />} onClick={handleOpenAddClient} />
					</Grid>
					<Grid item>
						<CommonButton buttonName={<FiFilter style={{ fontSize: '22px' }} />} onClick={handleFilterDrawer} />
					</Grid>
					<AddFilter setUserListData={setUserListData} openDrawer={filterDrawer} formik={formik} toggleDrawer={handleFilterDrawer} />
				</Grid>
			</Stack>

			<TableContainer component={Paper} className={classes.tableContainer}>
				<Table stickyHeader aria-label="simple table">
					<TableHead>
						<TableRow>
							<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
								Name
							</StyledTableCell>
							<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
								Email
							</StyledTableCell>
							<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
								Conatct Number
							</StyledTableCell>
							<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
								Created Date
							</StyledTableCell>
							<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
								Status
							</StyledTableCell>
							<StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>

							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{userListData?.map((row, index) => {
							const {
								id,
								firstName,
								lastName,
								email,
								status,
								contact,
								createdAt
							} = row;

							const name = firstName.trim() ? `${firstName} ${lastName}` : "-";

							return (
								<TableRow
									key={index}
									sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									style={{ cursor: "pointer" }}
								>
									<TableCell component="th" scope="row">
										{name}
									</TableCell>
									<TableCell>{email || "-"}</TableCell>
									<TableCell>{contact || "-"}</TableCell>
									<TableCell>{moment(createdAt).format("LLL")}</TableCell>
									<TableCell>
										<Switch
											color="primary"
											onChange={() => setHandelModal({
												open: true,
												type: 'status',
												message: 'Are you sure you want to change the status?',
												id
											})}
											checked={status == 1 ? true : false}
											disabled={status == 2 ? true : false}
										/>
									</TableCell>
									<TableCell onClick={(e) => {
										setEditId(row);
									}}>
										<Grid container spacing={2} alignItems="center" flexWrap="nowrap">
											<Grid item>
												<LockIcon sx={{ cursor: 'pointer' }} onClick={() => handlePasswordChange(row)} />
											</Grid>
											<Grid item>
												<EditIcon onClick={() => {
													handleEditClient(row)
												}} sx={{ width: '20px', height: '20px' }} />
											</Grid>
											<Grid item>
												<DeleteIcon onClick={() => handleDeleteAdmin(id)} sx={{ width: '20px', height: '20px' }} />
											</Grid>
										</Grid>
									</TableCell>
								</TableRow>
							);
						})}
						{
							userListData === null ? (
								<TableRow>
									<TableCell
										sx={{
											color: "silver",
											textAlign: "center",
											paddingTop: "90px",
											borderBottom: "none",
											fontSize: "30px",
										}}
										colSpan="6"
									>
										No records to display
									</TableCell>
								</TableRow>
							) : (
								userListData.length === 0 && <TableRowsLoader rowsNum={10} />
							)
						}
					</TableBody >
				</Table >
			</TableContainer >

			<Paginations
				count={totalPages}
				data={userListData}
				totalRecords={totalRecords}
				page={page}
				onChange={handleChangePage}
				defaultPage={1}
				color="primary"
				size="large"
				shape="rounded"
			/>

			{/* Change password model */}
			<Dialog open={openAdd} onClose={handleClose} sx={{ '& .MuiDialog-paper': { width: '500px' } }
			}>
				<DialogTitle>Change Password</DialogTitle>
				<ChangePassword setUserListData={setUserListData} handleClose={handleClose} changePassId={changePassId} setShow={setShow} setChangePassId={setChangePassId} />
			</Dialog>


			{/* Add edit client model */}
			<Dialog open={addEditClientPopup} onClose={handleCloseAddClient} maxWidth='md' >
				<AddClient getUserList={getUserList} setUserListData={setUserListData} editClientData={editClientData} handleCloseAddClient={handleCloseAddClient} setShow={setShow} setEditClientData={setEditClientData} nameEditBtn={editClientData && editClientData.id ? "Edit" : "Add"}
				/>
			</Dialog>

			<Notification show={show} setShow={setShow} />

			<StatusChangeModal
				handleAction={confirmDeleteAdmin}
				handleCloseModal={handleCloseModal}
				setHandelModal={setHandelModal}
				handelModal={handelModal}
			/>

		</>
	)
}

export default Clientmanagement