import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout/Layout";
import { ThemeProvider } from "@mui/material";
import { theme } from "./helper/Theme";
import Login from "./pages/login/Login";
import PrivateRoute from "../src/Routes/PrivateRoute";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { loadUser } from "./Service/auth.service";
import { loginUserApi } from "./state/action/authAction";
import Nodata from "./components/Nodata";
import { useUserNotificationSubscribeHook } from "./Notification";

function App() {
  let routeElement = null;
  const location = useLocation();
  const dispatch = useDispatch();
  const pathname = location.pathname.substr(1);
  const { registerAndSubscribe } = useUserNotificationSubscribeHook();


  const { user } = useSelector((state) => state?.root?.auth);
  const isAuthenticate = useSelector((store) => store?.root?.auth?.isAuthenticate);

  const slug = location.pathname.split("/")[1];

  const [roleName, setRoleName] = useState("admin");
  const Navigate = useNavigate();

  const getUsetData = async (data) => {
    let res = await loadUser(data);
    if (res?.status) {
      registerAndSubscribe(res?.data && res?.data?.id);

      dispatch(loginUserApi(res?.data));
    }
  };

  useEffect(() => {
    if (pathname == "login") {
      if (user && user?.role && user?.role) {
        Navigate("/");
      }
    }
    setRoleName(user && user?.role);
  }, [user]);

  useEffect(() => {
    if (pathname != "login") {
      const token = localStorage.getItem("token");
      const payload = {
        token: token,
      };
      getUsetData(payload);
    }
  }, []);

  useEffect(() => {
    if (pathname == "" && isAuthenticate == false) {
      Navigate("/login");
    }
  }, [pathname, isAuthenticate]);

  if (roleName) {
    routeElement = (
      <PrivateRoute>
        <Layout />
      </PrivateRoute>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="background_Image">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/404" element={<Nodata page="terms-of-services" />} />
          <Route path="*" element={routeElement} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
