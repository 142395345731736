import React, { useState, useEffect } from 'react';
import Loaders from '../../components/common/Loader/Loader';
import PageHeading from '../../components/common/PageHeading'
import CounterCard from './CounterCard'
import { Grid, Stack, Typography } from '@mui/material'
import { useStyles } from '../../helper/Theme'
import WareHouseChart from './WareHouseChart';
import Movement from './Movement';
import { getDashboardData } from '../../Service/dashboard.service';
import TopMoveProduct from './TopMoveProduct';

const DashBoard = () => {
  const classes = useStyles();
  const [dataFromChild, setDataFromChild] = React.useState('');
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState([]);
  const [movementListData1, setMovementListData1] = useState([]);
  const [locationProduct, setLocationProduct] = useState([]);
  const [movementListData, setMovementListData] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    getDashboardDetails();
  }, []);

  const getDashboardDetails = async () => {
    const res = await getDashboardData();
    if (res.status == true) {
      setDashboardData(res.data)
      setLocationProduct(res.data?.locationProduct)
      setMovementListData(res.data?.trackData)
      setMovementListData1(res.data?.trackData1)
    } else {
      setDashboardData([])
      setLocationProduct([])
      setMovementListData([])
      setMovementListData1([])
    }
  }

  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };
  return (
    <>
      <Loaders isOpen={loading} />
      <Stack direction='row' alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, flexDirection: { xs: 'column', sm: 'row' }, gap: '8px' }}>
        <PageHeading heading='Dashboard' />
        <Grid container justifyContent='flex-end' alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit">
          <Grid item>
            {/* <DateRange /> */}
          </Grid>
        </Grid>
      </Stack>
      <Grid container spacing={{ xs: '10px', sm: 3 }}>
        <Grid item xs={12}>
          <CounterCard dashboardData={dashboardData} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: '10px', sm: 3 }}>
            <Grid item md={12} xs={12}>
              <WareHouseChart locationProduct={locationProduct} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize={'20px'} fontWeight={'600'} mb={1}>Latest Master Movement</Typography>
          <Movement movementListData={movementListData} />
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize={'20px'} fontWeight={'600'} mb={1}>Top 10 Moving Product</Typography>
          <TopMoveProduct movementListData={movementListData1} />
        </Grid>
      </Grid>
    </>
  )
}

export default DashBoard