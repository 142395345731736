import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DashBoard from '../../pages/dashboard/Dashboard';
import Movement from '../../pages/Product-movement/Movement';
import Product from '../../pages/Product/Product';
import Location from '../../pages/Location/Location';
import User from '../../pages/User/User';
import Reports from '../../pages/Reports/Report';
import MyProfile from '../../pages/Profile/Profile';
import Clientmanagement from '../../pages/clientmanagement/Clientmanagement';
import MovementDetails from '../../pages/Product-movement/MovementDetails';
import { useSelector } from "react-redux";
import Notification from '../../pages/Notification/Notifications';
import SampleManagement from '../../pages/Samplemanagement/SampleManagement';
import SampleProductDeatils from '../../pages/Samplemanagement/SampleProductDeatils';
import NewMaster from '../../pages/NewMaster/Master';
import StockManagement from '../../pages/StockManagement/Stock';
import StockDetails from '../../pages/StockManagement/StockDetails';
import NewMasterDetails from '../../pages/NewMaster/NewMasterDetails';

const Main = () => {
  const { user } = useSelector((state) => state?.root?.auth);

  const pages = [
    { path: '/', element: <DashBoard /> },
    { path: '/product-movement', element: <Movement /> },
    { path: '/product-movement/details', element: <MovementDetails /> },
    { path: '/master', element: <Product /> },
    { path: '/location', element: <Location /> },
    { path: '/user', element: user && user.role === 2 ? null : <Clientmanagement />, name: "user" },
    { path: '/reports', element: <Reports /> },
    { path: '/profile', element: <MyProfile /> },
    { path: '/notification', element: <Notification /> },
    { path: '/sample-management', element: <SampleManagement /> },
    { path: '/sample-management/details', element: <SampleProductDeatils /> },
    { path: '/new-master-request', element: <NewMaster /> },
    { path: '/stock-management', element: <StockManagement /> },
    { path: '/stock-details', element: <StockDetails /> },
    { path: '/master-details', element: <NewMasterDetails /> }
  ];

  return (
    <Routes>
      {pages.map(({ path, element }, index) => (
        <Route key={index} path={path} element={element} />
      ))}
    </Routes>
  );
};

export default Main;
