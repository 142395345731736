import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import PageHeading from '../../components/common/PageHeading';
import { theme, useStyles } from '../../helper/Theme';
import {
  Grid, Stack, TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  Typography
} from '@mui/material';
import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import { ProductList, deleteProduct, exportMasterStock, productStatus } from '../../Service/product.service';
import moment from "moment";
import Notification from '../../components/common/Notification/Notification';
import { EditIcon, AddIcon, DeleteIcon } from '../../components/Constants';
import CommonButton from '../../components/common/Button';
import Skeleton from '@mui/material/Skeleton';
import StatusChangeModal from '../../components/common/modal/DeleteModal';
import TableSearch from '../../components/common/Table/TableSearch';
import { FiFilter } from '../../helper/Icons';
import Pagination from "../../components/common/Table/Pagination";
import AddFilter from './AddFilter';
import CommonModal from '../../components/common/Modal';
import { useFormik } from 'formik';
import { productFilterInitialValues } from '../../helper/initialValues';
import { DELETE_MESSAGE, STATUS_MESSAGE } from '../../components/common/Text';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const Product = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [productListData, setProductListData] = useState([]);
  const [page, setPage] = useState(localStorage.getItem("productData") ? JSON.parse(localStorage.getItem("productData")).page : 1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [sortDate, setSortDate] = useState(false);
  const [search, setSearch] = useState("");
  const [shortKey, setShortKey] = useState('createdAt');
  const [sortBy, setSortBy] = useState(false);
  const [handelModal, setHandelModal] = useState({
    open: false,
    type: '',
    message: ''
  });
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: false
  });

  const [show, setShow] = useState({
    show: false,
    type: '',
    message: ''
  });
  const [refresh, setRefresh] = useState(false);
  const [filterDrawer, setFilterDrawer] = React.useState(false);
  const handleFilterDrawer = () => {
    setFilterDrawer(!filterDrawer);
  };

  const formik = useFormik({
    initialValues: productFilterInitialValues,
    onSubmit: async (values) => {
      setRefresh(!refresh)
    },
  });

  const handleChangePage = (event, value) => {
    setPage(value);
    getProductList(value, search);
  };

  const handleCloseModal = () => {
    setHandelModal({
      open: false,
      type: '',
      message: '',
      id: ""
    })
  }

  const confirmDeleteAdmin = async () => {
    if (handelModal.type == "download") {
      handelExport(search);
    }
    if (handelModal.id) {
      let res = "";
      if (handelModal.type == "status") {
        res = await productStatus(handelModal.id);
      } else {
        res = await deleteProduct(handelModal.id);
      }

      if (res.status == true) {
        setProductListData(res.data)
        setRefresh(!refresh)
        setShow({
          show: true,
          type: 'success',
          message: res.message || handelModal.type == "status" ? STATUS_MESSAGE : DELETE_MESSAGE
        })
        setHandelModal({
          open: false,
          type: '',
          message: '',
          id: ""
        })
      } else {
        setShow({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong'
        })
      }
    }
  }

  const handleDeleteAdmin = (id) => {
    if (id) {
      setHandelModal({
        open: true,
        type: 'delete',
        message: 'Are you sure you want to delete this client?',
        id: id
      })
    }
  }

  const handelExport = async (search) => {
    let query = `search=${search}&status=${formik.values.status}&name=${formik.values.product}&orderBy=${shortKey}&sortFlag=${sortBy}`;
    const res = await exportMasterStock(query);

    const url = window.URL.createObjectURL(new Blob([res.data]));

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'master-stock.csv');
    document.body.appendChild(link);
    link.click();
    setShow({
      show: true,
      type: 'success',
      message: res.message || 'Exported successfully'
    })
    setHandelModal({
      open: false,
      type: '',
      message: ''
    })

  }
  const getProductList = async (page, search) => {
    let query = `page=${page}&limit=${pagePerSize}&search=${search}&status=${formik.values.status}&name=${formik.values.product}&orderBy=${shortKey}&sortFlag=${sortBy}`

    const res = await ProductList(query);
    if (res.status) {
      setProductListData(res?.data)
      setTotalRecords(res?.total || 0);
      setTotalPages(Math.ceil(res?.total / pagePerSize));

    } else {
      setProductListData(null)
      setTotalRecords(0);
      setTotalPages(0);
    }
  }

  const getSearchKeywords = (e) => {
    setPage(1);
    setSearch(e.target.value);
  }

  const TableRowsLoader = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
      </TableRow>
    ));
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("productData"));
    if (data) {
      setTotalRecords(0);
      setPage(data.page);
      if (data.search) {
        setSearch(data.search);
      }
      formik.setValues(data.values);
      formik.handleSubmit();
      localStorage.removeItem("productData");
    } else {
      getProductList(page, search);
    }
  }, [refresh, search, sortBy, shortKey]);

  const handleSortClick = (name, by) => {
    switch (name) {
      case 'createdAt':
        setSortBy(!by)
        setSortDate((current) => !current)
        setShortKey('createdAt')
        break;

      default:
        setSortBy(!sortDate)
        setSortDate(false)
        setShortKey('createdAt')
        break;
    }
  };

  return (
    <>
      <PageHeading heading='Master Stock' />
      <Stack alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: '8px' }}>
        <TableSearch value={search} onChange={getSearchKeywords} />
        <Grid container justifyContent='flex-end' alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit">
           <Grid item>
              <CommonButton className='export_button' buttonName={'Export'}
                onClick={() => {
                  setHandelModal({ open: true, type: 'download', message: 'Are you sure you want to export?' })
                }} />
            </Grid>
          <Grid item>
            <CommonButton buttonName={<AddIcon sx={{ fontSize: '22px' }} />} onClick={() => setIsModalOpen({ open: true, currentComponent: "addProduct", isEdit: false })} />
          </Grid>
          <Grid item>
            <CommonButton buttonName={<FiFilter style={{ fontSize: '22px' }} />} onClick={handleFilterDrawer} />
          </Grid>
          <AddFilter formik={formik} setProductListData={setProductListData} openDrawer={filterDrawer} toggleDrawer={handleFilterDrawer} />
        </Grid>
      </Stack>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Image
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Product Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Location
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Rack Location
              </StyledTableCell>
              <StyledTableCell style={{ cursor: 'pointer' }} sx={{ fontWeight: '700', color: '#000' }} onClick={() => handleSortClick('createdAt', sortDate)}>
                Date   {sortDate ? <FaArrowUp style={{ color: '#adadad' }} /> : <FaArrowDown style={{ color: '#adadad' }} />}
              </StyledTableCell>

              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Status
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>

              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productListData?.map((row, index) => {
              var {
                id,
                name,
                lastName,
                email,
                status,
                rackLocation,
                imageData,
                trackData
              } = row;
              var location = trackData && trackData.length > 0 ? trackData[0] : '';
              email = email ? email : "-";
              return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={(e) => {
                    navigate(`/product-movement/details`, {
                      state: {
                        id: id,
                      }
                    })
                    localStorage.setItem("productData", JSON.stringify({
                      page: page,
                      search: search,
                      values: formik.values,
                    }));
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell component="th" scope="row">
                    {imageData != "" ?
                      <img src={imageData} alt='' style={{ width: '60px', height: '60px', objectFit: 'cover' }} /> : <img src="https://asia-pacific-ceramic.s3.ap-south-1.amazonaws.com/images/main_logo.png" alt='' style={{ width: '60px', height: '60px' }} />}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {name.length > 20 ? `${name.substring(0, 20)}...` : name}
                  </TableCell>
                  <TableCell>
                    <Stack>
                      <Typography fontSize={'16px'}>{location?.locationName}</Typography>
                      <Typography fontSize={'13px'}>{`${location?.address}, ${location?.city}, ${location?.state}, ${location?.country}`}</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {rackLocation}
                  </TableCell>
                  <TableCell onClick={(e) => { e.stopPropagation() }}>
                    {moment(row.createdAt).format("LLL")}
                  </TableCell>
                  <TableCell onClick={(e) => { e.stopPropagation(); setHandelModal({ open: true, type: 'status', message: 'Are you sure you want to change the status?', id }) }}>

                    <Switch
                      color="primary"
                      onChange={() => setHandelModal({
                        open: true,
                        type: 'status',
                        message: 'Are you sure you want to change the status?',
                        id
                      })}
                      checked={status == 1 ? true : false}
                      disabled={status == 2 ? true : false}
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: 'end' }} onClick={(e) => { e.stopPropagation() }}>
                    <Grid container spacing={2} alignItems="center" flexWrap="nowrap">
                      <Grid item>
                        <EditIcon onClick={() => setIsModalOpen({ open: true, currentComponent: "addProduct", isEdit: true, id: id })} sx={{ width: '20px', height: '20px' }} />
                      </Grid>
                      <Grid item>
                        <DeleteIcon onClick={(e) => {
                          e.stopPropagation()
                          handleDeleteAdmin(id)
                        }
                        } sx={{ width: '20px', height: '20px' }} />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
            {productListData === null ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  No records to display
                </TableCell>
              </TableRow>

            ) : (
              productListData.length === 0 ? (<TableRowsLoader rowsNum={10} />) : ("")
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={page}
        onChange={handleChangePage}
        color="primary"
        sx={{ marginTop: '20px', textAlign: 'center' }}
        data={productListData}
        totalRecords={totalRecords}
      />

      <Notification show={show} setShow={setShow} />
      <CommonModal
        modalOpen={isModalOpen}
        data={setProductListData}
        refresh={refresh}
        setRefresh={setRefresh}
        show={show}
        setShow={setShow}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: false })
        } />

      <StatusChangeModal
        handleAction={confirmDeleteAdmin}
        handleCloseModal={handleCloseModal}
        setHandelModal={setHandelModal}
        handelModal={handelModal}
      />


    </>
  )
}

export default Product