import { Autocomplete, TextField, Typography } from '@mui/material'
import React from 'react'

const list = [
    { label: 'Merry Gold' },
    { label: 'Merry G' },
    { label: 'Marc Gol' },
    { label: 'Merry lea' },
];

const CommonAutocomplete = (props) => {
    const { labelinput, data, name, onChange, value, formik, name1 } = props
    return (
        <>
            {labelinput && <Typography fontSize={'16px'} fontWeight={500} mb={'2px'}>{labelinput}</Typography>}
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={data || list}
                sx={{ width: '100%' }}
                value={value == '' ? null : value}
                onChange={onChange}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="" placeholder="" />}
            />
            {(formik?.touched[props.name1] && formik?.errors[props.name]) &&
                <p class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">{formik?.errors[props.name]}</p>
            }
        </>
    )
}

export default CommonAutocomplete