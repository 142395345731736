import React from 'react';
import { Autocomplete, TextField, Typography, List, Paper } from '@mui/material';
import { FixedSizeList } from 'react-window';

const list = [
  { name: 'Merry Gold' },
  { name: 'Merry G' },
  { name: 'Marc Gol' },
  { name: 'Merry lea' },
];

const CommonAutocomplete1 = (props) => {
  const { labelinput, data, name, onChange, value, formik, name1 } = props;

  const VirtualizedList = React.forwardRef(({ children, ...rest }, ref) => {
    const itemCount = children.length;

    return (
      <FixedSizeList
        height={Math.min(200, itemCount * 50)}
        width="100%"
        itemCount={itemCount}
        itemSize={50}
        outerElementType={React.forwardRef((props, ref) => <div ref={ref} {...props} />)}
        style={{
          border: 'none',

          backgroundColor: 'white',

        }} // Remove border from FixedSizeList
      >
        {({ index, style }) => (
          <List
            component="div"
            {...rest}
            style={{
              ...style, margin: 0, padding: 3, border: 'none',
              //hover then background color change
              '&:hover': {
                backgroundColor: '#f5f5f5 !important',
              },

            }}
            key={index}
          >
            {children[index]}
          </List>
        )}
      </FixedSizeList>
    );
  });

  return (
    <>
      {labelinput && (
        <Typography fontSize="16px" fontWeight={500} mb="2px">
          {labelinput}
        </Typography>
      )}
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={data || list}
        sx={{ width: '100%' }}
        value={value === '' ? null : value}
        onChange={onChange}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="" placeholder="" />}
        ListboxComponent={VirtualizedList}
        PaperComponent={(props) => <Paper {...props} sx={{ border: 'none' }} />} // Remove border from Paper
      />
      {formik?.touched[name1] && formik?.errors[name] && (
        <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
          {formik?.errors[name]}
        </p>
      )}
    </>
  );
};

export default CommonAutocomplete1;
