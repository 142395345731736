import * as React from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Bell, Setting, ProfileIcon, Notificationuser } from '../Constants'
import { Box, Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useStyles } from '../../helper/Theme';
import { AccountCircleIcon, LogoutIcon } from '../../assets/icons/Icons';
import LogoutModal from '../../components/common/modal/logoutModal';
import { logoutApi } from '../../state/action/authAction';
import { useDispatch, useSelector } from 'react-redux';
import PasswordIcon from '@mui/icons-material/Password';
import { Stack } from '@mui/material';
import CommonModal from '../common/Modal';
import Notification from '../common/Notification/Notification';

const MyComponent = styled('div')({
  borderRadius: '10px',
  border: '1px solid #FFF',
  background: '#F6F8F9',
  boxShadow: '2px 3px 10px 0px rgba(0, 0, 0, 0.15)',
  display: 'flex'
});

const Header = () => {
  const theme = useTheme();
  const classes = useStyles();
  const medium = useMediaQuery(theme.breakpoints.down('md'));
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useSelector((state) => state?.root?.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [myprofile, setMyProfile] = useState(null);
  const [profileIcon, setProfileIcon] = useState(false);
  const [userId, setUserId] = useState('');
  const [show, setShow] = useState({
    show: false,
    type: '',
    message: ''
  });


  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: '', head: ''
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = (event) => {
    setMyProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setMyProfile(null);
  };

  React.useEffect(() => {
    if (user && user?.id) {
      setUserId(user?.id)
    }
  }
    , [user])

  React.useEffect(() => {
    if (user?.profile_image) {
      setProfileIcon(user?.profile_image)
    }
  }, [user]);



  const handelLogout = () => {
    localStorage.removeItem('token');
    dispatch(logoutApi());
  }

  return (
    <>
      <Grid item>
      </Grid>
      <Grid item sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '15px' }}>
        {/* <MyComponent
          sx={{ padding: { xs: '8px' }, cursor: 'pointer' }}
          onClick={handleClick}
        >
          <img src={Bell} alt='Bell' width='25' />
        </MyComponent> */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box paddingX={2} paddingY={1} onClick={handleClose}>
            <Grid container spacing={3} paddingBottom={1} alignItems='center' justifyContent='space-between'>
              <Grid item fontSize={18} fontWeight={600} sx={{ color: 'var(--primary)' }}>Notification</Grid>
              <Grid item fontSize={15} fontWeight={500} sx={{ color: 'var(--primary)' }}>Mark all as read</Grid>
            </Grid>
            {
              [1, 2, 3].map(() => {
                return (
                  <Box container spacing={3} className={classes.cardContainer} padding={2} marginTop={2} sx={{ cursor: 'pointer' }}>
                    <Grid container justifyContent='space-between' alignItems='center' flexWrap={'nowrap'}>
                      <Grid item>
                        <Grid container>
                          <Grid item xs={12}>New client added </Grid>
                          <Grid item xs={12}> on May 23, 2023 at 6:00 pm</Grid>
                        </Grid>
                      </Grid>
                      <Grid item><img src={Notificationuser} /></Grid>
                    </Grid>
                  </Box>
                );
              })
            }
          </Box>
        </Menu>
        {/* <MyComponent onClick={() => { navigate('/admin/setting') }} sx={{ padding: { xs: '8px 9px 9px' }, cursor: 'pointer' }}
        >
          <img src={Setting} alt='Setting' width='18' />
        </MyComponent> */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: { md: '20px', lg: '15px' }, cursor: 'pointer' }}
          onClick={handleClickProfile}>
          {
            medium ? ('') : <>
              <Divider orientation='vertical' flexItem />
              <div> {user?.firstName} {user?.lastName}</div>
            </>
          }
          <img src={
            profileIcon == "" ? ProfileIcon : profileIcon
          } alt='ProfileIcon' width={40} />
        </Box>
        <Menu
          anchorEl={myprofile}
          open={Boolean(myprofile)}
          onClose={handleCloseProfile}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => { navigate('/profile') }}>
            <div onClick={handleCloseProfile} style={{ display: 'flex' }} key='myprofile'>
              <AccountCircleIcon color='primary' /> &nbsp; My Profile
            </div>
          </MenuItem>
          <MenuItem onClick={() => {
            // if (userId === '') {
            //   window.location.reload()
            // } else {
            setIsModalOpen({ open: true, currentComponent: "changePassword", id: userId })
            // }
          }
          } >
            <Stack flexDirection={'row'} gap={1} alignItems={'center'}><PasswordIcon color='primary' /> &nbsp; Change Password</Stack>
          </MenuItem>
          <MenuItem key='logout'>
            <LogoutModal handelLogout={handelLogout} />
          </MenuItem>
        </Menu>
      </Grid>
      <CommonModal
        show={show}
        modalOpen={isModalOpen}
        setShow={setShow}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: false })
        }
      />
      <Notification show={show} setShow={setShow} />

    </>
  )
}

export default Header