import React, { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack';
import { Checkbox, Container, Grid, Typography } from '@mui/material';
import CommonButton from '../../components/common/Button';
import CommonInput from '../../components/common/Input';
import { useNavigate } from 'react-router-dom';
import { loginInitialValues } from '../../helper/initialValues';
import { loginValidationSchema } from '../../helper/validation';
import { useFormik } from "formik";
import { login } from '../../Service/auth.service';
import Notification from '../../components/common/Notification/Notification';
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import { loginUserApi } from '../../state/action/authAction';
import Loaders from '../../components/common/Loader/Loader';

const LogIn = () => {
    const [show, setShow] = useState({
        show: false,
        type: '',
        message: ''
    });
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cookies = new Cookies();




    const formik = useFormik({
        initialValues: loginInitialValues,
        onSubmit: async (value) => {
            setLoading(true);

            const payload = {
                email: value.email,
                password: value.password,
            };

            const res = await login(payload);
            if (res.status) {
                setLoading(false);

                cookies.set('token', res?.data?.token, { path: '/' });
                cookies.set("email", value.email, { path: "/" });
                cookies.set("password", value.password, { path: "/" });
                localStorage.setItem('token', res?.data?.token);

                dispatch(loginUserApi(res?.data));

                setShow({
                    show: true,
                    type: 'success',
                    message: res.message || 'Login Successfully'
                })
            } else {
                setLoading(false);

                setShow({
                    show: true,
                    type: 'error',
                    message: res.message || 'Something went wrong'
                })
            }
        },
        validationSchema: loginValidationSchema,
    });


    useEffect(() => {
        const userEmail = cookies.get("email");
        const userPassword = cookies.get("password");

        if (userEmail && userPassword) {
            formik.setFieldValue("email", userEmail);
            formik.setFieldValue("password", userPassword);
        }
    }, []);



    return (
        <Container sx={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: { xs: '10px', sm: '20px' },
            maxWidth: { xs: '370px', sm: '500px' },
        }}>
            <Loaders isOpen={loading} />

            <Typography align='center' sx={{ color: 'var(--heading)', fontSize: '30px', fontWeight: '800', }}>Login</Typography>
            <Typography align='center' className='login_para'>Welcome back to <span>Asia Pacific Ceramic</span> </Typography>
            <Typography align='center'>
                <Stack
                    component="form"
                    sx={{ width: { xs: '340px', sm: '450px' } }}
                    spacing={3}
                    noValidate
                    autoComplete="off"
                >
                    <CommonInput
                        placeholder="Email"
                        name="email"
                        formik={formik}
                        maxLength={50}


                    />
                    <CommonInput
                        placeholder="Password"
                        name="password"
                        formik={formik}
                        maxLength={50}
                        passwordInput
                    />
                </Stack>
            </Typography>
            <Typography align='center' sx={{ width: '100%' }}>
                <Grid container sx={{ justifyContent: 'space-between' }}>
                    <Grid item sx={6}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item sx={6} className=''>
                                <Checkbox
                                    defaultChecked
                                    sx={{
                                        padding: '0',
                                        color: 'var(--primary)',
                                        '& .MuiSvgIcon-root': { fontSize: 28 },
                                        '&.Mui-checked': {
                                            color: 'var(--primary)',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item sx={6} className='login_para'>Remember me</Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item sx={6} className='login_para'><a href='/user-forgotPassword'>Forget Password?</a></Grid> */}
                </Grid>
            </Typography>
            <Typography align='center' sx={{ paddingTop: { xs: '10px', sm: '18px' } }}>
                <CommonButton buttonName='Login' onClick={formik.handleSubmit}
                />
            </Typography>
            <Notification show={show} setShow={setShow}
            />
        </Container>
    )
}

export default LogIn