export const DELETE_MESSAGE = 'User deleted successfully'
export const STATUS_MESSAGE = 'User status updated successfully'
export const USER_UPDATE = 'User updated successfully'
export const USER_CREATE = 'User created successfully'
export const SOMETHING_WENT_WRONG = 'Something went wrong'
export const CHANGE_PASSWORD = 'Change Password Successfully'
export const PASSWORD_NOT_MATCH = 'New password and confirm password must be same'
export const RE_ENTER_PASSWORD = 'Re-enter Password'
export const ENTER_PASSWORD = 'Enter Password'

