import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import PageHeading from '../../components/common/PageHeading';
import { theme, useStyles } from '../../helper/Theme';
import {
  Grid, Stack, TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  Typography
} from '@mui/material';
import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import { MasterList, deleteMaster, masterStatus } from '../../Service/master.service';
import moment from "moment";
import Notification from '../../components/common/Notification/Notification';
import { EditIcon, AddIcon, DeleteIcon } from '../../components/Constants';
import CommonButton from '../../components/common/Button';
import Skeleton from '@mui/material/Skeleton';
import StatusChangeModal from '../../components/common/modal/DeleteModal';
import TableSearch from '../../components/common/Table/TableSearch';
import { FiFilter } from '../../helper/Icons';
import Pagination from "../../components/common/Table/Pagination";
import AddFilter from './AddFilter';
import CommonModal from '../../components/common/Modal';
import { useFormik } from 'formik';
import { productFilterInitialValues } from '../../helper/initialValues';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const Master = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [masterListData, setMasterListData] = useState([]);
  const [page, setPage] = useState(localStorage.getItem("newreq") ? JSON.parse(localStorage.getItem("newreq")).page : 1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [handelModal, setHandelModal] = useState({
    open: false,
    type: '',
    message: ''
  });
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: false
  });

  const [show, setShow] = useState({
    show: false,
    type: '',
    message: ''
  });
  const [refresh, setRefresh] = useState(false);
  const [filterDrawer, setFilterDrawer] = React.useState(false);
  const handleFilterDrawer = () => {
    setFilterDrawer(!filterDrawer);
  };

  const formik = useFormik({
    initialValues: productFilterInitialValues,
    onSubmit: async (values) => {
      setRefresh(!refresh)
    },
  });

  const handleChangePage = (event, value) => {
    setPage(value);
    getMasterList(value, search);
  };

  const handleCloseModal = () => {
    setHandelModal({
      open: false,
      type: '',
      message: '',
      id: ""
    })
  }

  const confirmDeletMaster = async () => {
    if (handelModal.id) {
      let res = "";
      if (handelModal.type == "status") {
        res = await masterStatus(handelModal.id);
      } else {
        res = await deleteMaster(handelModal.id);
      }

      if (res.status == true) {
        setMasterListData(res.data)
        setRefresh(!refresh)
        setShow({
          show: true,
          type: 'success',
          message: res.message || handelModal.type == "status" ? "Master Status changed successfully" : "Master deleted successfully"
        })
        setHandelModal({
          open: false,
          type: '',
          message: '',
          id: ""
        })
      } else {
        setShow({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong'
        })
      }
    }
  }

  const handleDeleteAdmin = (id) => {
    if (id) {
      setHandelModal({
        open: true,
        type: 'delete',
        message: 'Are you sure you want to delete this master?',
        id: id
      })
    }
  }

  const getMasterList = async (page, search) => {
    setMasterListData([]);
    let query = `page=${page}&limit=${pagePerSize}&search=${search}&status=${formik.values.status}`

    const res = await MasterList(query);
    if (res.status) {
      setMasterListData(res?.data)
      setTotalRecords(res?.total || 0);
      setTotalPages(Math.ceil(res?.total / pagePerSize));

    } else {
      setMasterListData(null)
      setTotalRecords(0);
      setTotalPages(0);
    }
  }

  const getSearchKeywords = (e) => {
    setSearch(e.target.value);
  }

  const TableRowsLoader = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
      </TableRow>
    ));
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("newreq"));

    if (data) {
      setTotalRecords(0);
      setPage(data.page);
      if (data.search) {
        setSearch(data.search);
      }
      formik.setValues(data.values);
      formik.handleSubmit();
      localStorage.removeItem("newreq");
    } else {
      getMasterList(page, search);
    }
  }, [refresh, search]);

  return (
    <>
      <PageHeading heading='New Master Request' />
      <Stack alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: '8px' }}>
        <TableSearch value={search} onChange={getSearchKeywords} />
        <Grid container justifyContent='flex-end' alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit">
          <Grid item>
            <CommonButton buttonName={<AddIcon sx={{ fontSize: '22px' }} />} onClick={() => setIsModalOpen({ open: true, currentComponent: "addMaster", isEdit: false })} />
          </Grid>
          {/* <Grid item>
            <CommonButton buttonName={<FiFilter style={{ fontSize: '22px' }} />} onClick={handleFilterDrawer} />
          </Grid>
          <AddFilter formik={formik} setMasterListData={setMasterListData} openDrawer={filterDrawer} toggleDrawer={handleFilterDrawer} /> */}
        </Grid>
      </Stack>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>

              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Product Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Description
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Size
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Finish
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Random
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>

              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Date
              </StyledTableCell>

              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {masterListData?.map((row, index) => {
              var {
                id,
                name,
                email,
                status,
                trackData,
                size,
                description,
                finish,
                random
              } = row;
              return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={(e) => {
                    localStorage.setItem("newreq", JSON.stringify({
                      page: page,
                      search: search,
                      values: formik.values,
                    }));
                    navigate(`/master-details`, { state: { id: id } })
                  }
                  }
                  style={{ cursor: "pointer" }}
                >

                  <TableCell component="th" scope="row">
                    {name.length > 20 ? `${name.substring(0, 20)}...` : name}
                  </TableCell>
                  <TableCell component="th" scope="row" title={description} >
                    {description.length > 20 ? `${description.substring(0, 20)}...` : description}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {size}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {finish}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {random == null ? "-" : random}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <CommonButton
                      buttonName={"Convert to Product"}
                      fontSize='1rem' minWidth='40px' padding='6px 8px' onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpen({ open: true, currentComponent: "masterToProduct", isEdit: true, id: id })
                      }} />
                  </TableCell>
                  <TableCell onClick={(e) => { e.stopPropagation() }}>
                    {moment(row.createdAt).format("LLL")}
                  </TableCell>

                  <TableCell sx={{ textAlign: 'end' }} onClick={(e) => { e.stopPropagation() }}>
                    <Grid container spacing={2} alignItems="center" flexWrap="nowrap">
                      <Grid item>
                        <EditIcon onClick={() => setIsModalOpen({ open: true, currentComponent: "addMaster", isEdit: true, id: id })} sx={{ width: '20px', height: '20px' }} />
                      </Grid>
                      <Grid item>
                        <DeleteIcon onClick={(e) => {
                          e.stopPropagation()
                          handleDeleteAdmin(id)
                        }
                        } sx={{ width: '20px', height: '20px' }} />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
            {masterListData === null ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  No records to display
                </TableCell>
              </TableRow>

            ) : (
              masterListData.length === 0 ? (<TableRowsLoader rowsNum={10} />) : ("")
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={page}
        onChange={handleChangePage}
        color="primary"
        sx={{ marginTop: '20px', textAlign: 'center' }}
        data={masterListData}
        totalRecords={totalRecords}
      />

      <Notification show={show} setShow={setShow} />
      <CommonModal
        modalOpen={isModalOpen}
        data={setMasterListData}
        refresh={refresh}
        setRefresh={setRefresh}
        show={show}
        setShow={setShow}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: false })
        } />

      <StatusChangeModal
        handleAction={confirmDeletMaster}
        handleCloseModal={handleCloseModal}
        setHandelModal={setHandelModal}
        handelModal={handelModal}
      />


    </>
  )
}

export default Master