import { get, post, postToken, reload, put } from "../web.request";


export const MovementList = async (query) => {
    return await get(`/trakingList?${query}`);
}
export const MovementAdd = async (data) => {
    return await post(`/addProductTrack`, data);
}

export const updateMovement = async (data, id) => {
    return await put(`/updateMovment/${id}`, data);
}

export const movementDetail = async (id) => {
    return await get(`/trakingDetail/${id}`);
}
export const exportTracking = async (query) => {
    return await get(`/exportReport?${query}`);
}
