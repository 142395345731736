import React, { useState, useEffect } from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Stack, } from '@mui/material'
import { CloseIcon } from '../../../components/Constants';
import CommonButton from '../../../components/common/Button';
import CommonInput from '../../../components/common/Input';
import { addMaster, updateMaster, getMasterDetails } from '../../../Service/master.service';
import { useFormik } from 'formik';
import { masterAddFormInitialValues } from '../../../helper/initialValues';
import { addMasterValidationSchema } from '../../../helper/validation';
import Notification from '../../../components/common/Notification/Notification';

const AddProduct = ({ handleClose, modalOpen, data, setShow, setIsModalOpen, refresh, setRefresh }) => {
    const [show1, setShow1] = useState({
        show: false,
        type: '',
        message: ''
    });

    const formik = useFormik({
        initialValues: masterAddFormInitialValues,
        onSubmit: async (values) => {
            try {
                let res;
                const payload = {
                    "name": values.productName,
                    "specifications": "",
                    "description": values.productDescription,
                    "random": values.random,
                    "finish": values.finish,
                    "size": values.size,
                }
                if (modalOpen && modalOpen.isEdit) {
                    res = await updateMaster(payload, modalOpen.id);
                } else {
                    res = await addMaster(payload);
                }

                if (res.status == true) {
                    data([])
                    formik.resetForm();
                    setShow({
                        show: true,
                        type: 'success',
                        message: res.message || (modalOpen && modalOpen.isEdit ? "Master Request Updated Successfully" : "Master Request Added Successfully")
                    });
                    handleClose()
                    setRefresh(!refresh)
                } else {
                    setShow1({
                        show: true,
                        type: 'error',
                        message: res.message || "Something went wrong!"
                    });
                }

            } catch (error) {
                setShow1({
                    show: true,
                    type: 'error',
                    message: error.message || "Something went wrong!"
                });
            }
        },
        validationSchema: addMasterValidationSchema,
    });

    const getProductData = async () => {
        const res = await getMasterDetails(modalOpen.id);
        if (res.status == true) {
            formik.setFieldValue('productName', res.data.name)
            formik.setFieldValue('productDescription', res.data.description)
            formik.setFieldValue('random', res.data.random)
            formik.setFieldValue('size', res.data.size)
            formik.setFieldValue('finish', res.data.finish)
        }
    }

    useEffect(() => {
        if (modalOpen?.isEdit) {
            getProductData();
        }
    }, [modalOpen?.isEdit])



    return (
        <div>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {modalOpen?.isEdit ? 'Edit' : 'Add'} Master Request
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Box width={{ sm: '500px' }} >
                    <Stack gap={2}>

                        <Box>
                            <CommonInput labelinput='Product Name'
                                formik={formik}
                                name="productName"
                                value={formik.values.productName}
                                maxLength={200}
                            />

                        </Box>
                        <Box>
                            <CommonInput labelinput='Description'
                                formik={formik}
                                name="productDescription"
                                value={formik.values.productDescription}
                                maxLength={200}
                            />
                        </Box>



                        <Box>
                            <CommonInput labelinput='Random'
                                formik={formik}
                                name="random"
                                value={formik.values.random}
                                maxLength={10}
                            />
                        </Box>

                        <Box>
                            <CommonInput labelinput='Size'
                                formik={formik}
                                name="size"
                                value={formik.values.size}
                                maxLength={10}
                            />
                        </Box>
                        <Box>
                            <CommonInput labelinput='Finish'
                                formik={formik}
                                name="finish"
                                value={formik.values.finish}
                                maxLength={100}
                            />
                        </Box>
                    </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <CommonButton buttonName='cancel' size='small' color='white' onClick={handleClose} />
                <CommonButton buttonName={modalOpen?.isEdit ? 'Save' : 'Add'} size='small' onClick={formik.handleSubmit} />
            </DialogActions>
            <Notification show={show1} setShow={setShow1} />
        </div>
    )
}

export default AddProduct