import React from 'react';
import { Chart } from 'react-google-charts';
import { useStyles } from '../../helper/Theme';
import { Box } from '@mui/material';   

const WareHouseChart = ({ locationProduct }) => {
    const classes = useStyles();
    const generateChartData = (locationProduct) => {
        return [
          ['Category', 'Count', { role: "style" }],
          ...(locationProduct?.map((location) => {
            const { location_name, productCount } = location;
            return [location_name, productCount, '#ff7733'];
          }) || [])
        ];
      };     
    
    const data = generateChartData(locationProduct);
      
    return (
        <Box className={classes.cardContainer} padding={{ xs: '16px 10px', sm: 3 }} width={'100%'}>
            <Chart
                width={'100%'}
                height={'300px'}
                chartType="ColumnChart"
                data={data}
                options={{
                    title: 'Ware House Product Chart',
                    legend: 'none',
                    backgroundColor: 'transparent',
                    chartArea: { width: '90%', height: '80%' },
                }}
            />
        </Box>
    );
};

export default WareHouseChart;