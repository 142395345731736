import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import CommonButton from '../Button';
import { LogoutIcon } from '../../../assets/icons/Icons';

const DeleteModal = (props) => {
    const { handelLogout } = props;
    const [openDelete, setOpenDelete] = React.useState(false);

    const handleOpenModal = () => {
        setOpenDelete(true);
    };
    const handleCloseModal = () => {
        setOpenDelete(false);
    };
    return (
        <>

            <div onClick={handleOpenModal}>
                <Grid container spacing={1} onClick={handleOpenModal}>
                    <Grid item><LogoutIcon color='primary' /></Grid>
                    <Grid item>Logout</Grid>
                </Grid>
            </div>

            <Dialog open={openDelete} onClose={handleCloseModal} sx={{ '& .MuiDialog-paper': { width: '500px' } }}>
                <DialogTitle>Logout</DialogTitle>
                <DialogContent dividers >
                    <Typography className='myprofile_input_label' sx={{ paddingBottom: '2px' }}>
                        Are You Sure you want to logout ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <CommonButton onClick={handleCloseModal} buttonName="No" />
                    <CommonButton onClick={handelLogout} buttonName="Yes" />
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DeleteModal