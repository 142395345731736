import axios from "axios";

axios.defaults.withCredentials = false;
const AxiosWithoutToken = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // baseURL: "http://localhost:4001/api/v1",
    // baseURL: "https://stageinvapi.mobiginie.com/api/v1",
    crossDomain: false,
});


export default AxiosWithoutToken;



