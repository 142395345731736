import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import PageHeading from '../../components/common/PageHeading';
import { theme, useStyles } from '../../helper/Theme';
import {
  Grid, Stack, TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Switch
} from '@mui/material';
import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import { LocationList } from '../../Service/location.service';
import moment from "moment";
import Notification from '../../components/common/Notification/Notification';
import { EditIcon, AddIcon, DeleteIcon } from '../../components/Constants';
import CommonButton from '../../components/common/Button';
import Skeleton from '@mui/material/Skeleton';
import StatusChangeModal from '../../components/common/modal/DeleteModal';
import TableSearch from '../../components/common/Table/TableSearch';
import { FiFilter } from '../../helper/Icons';
import Pagination from "../../components/common/Table/Pagination";
import { DELETE_MESSAGE, STATUS_MESSAGE } from '../../components/common/Text';
import { locationStatus, deleteLocation } from '../../Service/location.service';
import Filter from './Filter';
import { addFilterInitialValues } from '../../helper/initialValues';
import { useFormik } from 'formik';
import CommonModal from '../../components/common/Modal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F0F0F80",
}));

const Location = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [productListData, setProductListData] = useState([]);
  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [addEditClientPopup, setAddEditClientPopup] = React.useState(false);
  const [deleteAdminId, setDeleteAdminId] = React.useState(null)
  const [search, setSearch] = useState("");
  const [filterDrawer, setFilterDrawer] = React.useState(false);
  const [handelModal, setHandelModal] = useState({
    open: false,
    type: '',
    message: ''
  });
  const [refresh, setRefresh] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: false,
    id: ""
  });

  const [locations, setLocations] = useState([]); // State to store locations

  const handleAddLocation = (newLocation) => {
    setLocations([...locations, newLocation]);
  };

  const [show, setShow] = useState({
    show: false,
    type: '',
    message: ''
  });

  const handleOpenAddClient = () => {
    setAddEditClientPopup(true);
  };
  const handleCloseAddClient = () => {
    setAddEditClientPopup(false);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    getLocationList(value, search);
  };

  const handleFilterDrawer = () => {
    setFilterDrawer(!filterDrawer);
  };


  const handleCloseModal = () => {
    setHandelModal({
      open: false,
      type: '',
      message: '',
      id: ""
    })
    setDeleteAdminId(null)
  }

  const confirmDeleteAdmin = async () => {
    if (handelModal.id) {
      let res = "";
      if (handelModal.type == "status") {
        res = await locationStatus(handelModal.id);
      } else {
        res = await deleteLocation(handelModal.id);
      }

      if (res.status == true) {
        setProductListData([])
        handleChangePage(null, page)
        setShow({
          show: true,
          type: 'success',
          message: res.message || handelModal.type == "status" ? STATUS_MESSAGE : DELETE_MESSAGE
        })
        setHandelModal({
          open: false,
          type: '',
          message: '',
          id: ""
        })
      } else {
        setShow({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong'
        })
      }
    }
  }

  const handleDeleteAdmin = (id) => {
    if (id) {
      setDeleteAdminId(id)
      setHandelModal({
        open: true,
        type: 'delete',
        message: 'Are you sure you want to delete this client?',
        id: id
      })
    }
  }

  const getLocationList = async (page, search) => {


    let query = `?page=${page}&limit=${pagePerSize}&search=${search}&status=${formik.values.status}&startDate=${formik.values.startDate}&endDate=${formik.values.endDate}`;

    if (formik.values.country?.id) {
      query += `&country_id=${formik.values.country?.id}`
    }
    if (formik.values.state?.id) {
      query += `&state_id=${formik.values.state?.id}`
    }
    if (formik.values.city?.id) {
      query += `&city_id=${formik.values.city?.id}`
    }



    const res = await LocationList(query);
    if (res.status) {
      setProductListData(res?.data)
      setTotalRecords(res?.total || 0);
      setTotalPages(Math.ceil(res?.total / pagePerSize));

    } else {
      setProductListData(null)
      setTotalRecords(0);
      setTotalPages(0);
    }
  }

  const getSearchKeywords = (e) => {
    setSearch(e.target.value);
  }

  const formik = useFormik({
    initialValues: addFilterInitialValues,
    onSubmit: async (values) => {
      getLocationList(page, search);
    }
  });

  const TableRowsLoader = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
      </TableRow>
    ));
  };

  useEffect(() => {
    getLocationList(page, search);
  }, [search, refresh])

  return (
    <>
      <PageHeading heading='Location' />
      <Stack alignItems="center" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: 1 }, flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: '8px' }}>
        <TableSearch value={search} onChange={getSearchKeywords} />
        <Grid container justifyContent='flex-end' alignItems='center' gap={{ xs: '5px', sm: '10px' }} width="inherit">
          <Grid item>
            <CommonButton buttonName={<AddIcon sx={{ fontSize: '22px' }} />} onClick={() => setIsModalOpen({ open: true, currentComponent: "addLocation", isEdit: false })} />
          </Grid>
          <Grid item>
            <CommonButton buttonName={<FiFilter style={{ fontSize: '22px' }} />} onClick={handleFilterDrawer} />
          </Grid>
          <Filter openDrawer={filterDrawer} setProductListData={setProductListData} formik={formik} toggleDrawer={handleFilterDrawer} />
        </Grid>
      </Stack>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Contact
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Location
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                date
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Status
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: '700', color: '#000' }}>
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productListData?.map((row, index) => {
              var {
                id,
                name,
                email,
                countryname,
                cityname,
                contact,
                address,
                statename,
                status
              } = row;
              email = email ? email : "-";
              return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell component="th" scope="row">
                    {name}
                  </TableCell>
                  <TableCell>{contact}</TableCell>

                  <TableCell>{`${address}, ${cityname}, ${statename}, ${countryname}`}</TableCell>
                  <TableCell>
                    {moment(row.created_at).format("LLL")}
                  </TableCell>
                  <TableCell onClick={(e) => { e.stopPropagation(); setHandelModal({ open: true, type: 'status', message: 'Are you sure you want to change the status?', id }) }}>
                    <Switch
                      color="primary"
                      onChange={() => setHandelModal({
                        open: true,
                        type: 'status',
                        message: 'Are you sure you want to change the status?',
                        id
                      })}
                      checked={status == 1 ? true : false}
                      disabled={status == 2 ? true : false}
                    />
                  </TableCell>

                  <TableCell sx={{ textAlign: 'end' }} onClick={(e) => { e.stopPropagation() }}>
                    <Grid container spacing={2} alignItems="center" flexWrap="nowrap">
                      <Grid item>
                        <EditIcon onClick={() => setIsModalOpen({ open: true, currentComponent: "addLocation", isEdit: true, id: id })} sx={{ width: '20px', height: '20px' }} />
                      </Grid>

                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
            {productListData === null ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  No records to display
                </TableCell>
              </TableRow>

            ) : (
              productListData.length === 0 ? (<TableRowsLoader rowsNum={10} />) : ("")
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={page}
        onChange={handleChangePage}
        color="primary"
        sx={{ marginTop: '20px', textAlign: 'center' }}
        data={productListData}
        totalRecords={totalRecords}
      />

      <Notification show={show} setShow={setShow} />
      <StatusChangeModal
        handleAction={confirmDeleteAdmin}
        handleCloseModal={handleCloseModal}
        setHandelModal={setHandelModal}
        handelModal={handelModal}
      />

      <CommonModal
        modalOpen={isModalOpen}
        setShow={setShow}
        setRefresh={setRefresh}
        data={setProductListData}
        refresh={refresh}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: false })
        } />
    </>
  )
}

export default Location